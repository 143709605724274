@import "../base";

.conversion-page-container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  width: 81.6rem;
  align-self: center;

  .header-container {
    h2 {
      font-size: 1.875rem;
    }
    .view-buttons-container {
      margin-top: 2.1rem;

      .button-secondary-square {
        margin-right: 3rem;
        width: 21.86rem;
        height: 3.33rem;

        a:hover {
          color: $turquoise;
        }
      }
    }
  }

  .body-container {
    margin-top: 4.66rem;
    display: flex;

    .bullet-container {
      display: flex;
      flex-direction: column;
      margin-left: 10rem;
      justify-content: space-evenly;

      .bullet-element {
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        span {
          margin-left: 1rem;
        }
      }
    }
  }
}

.svg-container {
  width: 10rem;
  height: 6.06rem;
}
