@import "../base";

.notification {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  background-color: $pink;
  border-radius: 2px;
  color: $text-inverse-color;
  font-size: 1.07rem;
  font-weight: 500;
  padding: 1.33rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .notification-icon-left {
    margin-right: 0.7rem;
  }

  &:hover {
    cursor: pointer;
  }
}

/* TODO: Below needs to be delted at some point*/

.legacy-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 10px;
}

.legacy-notification.legacy-notification-alert,
.legacy-notification .legacy-notification-alert {
  background: #d5ebf8;
  color: #097abf;
}

.legacy-notification.legacy-notification-warning,
.legacy-notification .legacy-notification-warning {
  background: #fce1c9;
  color: #e76d01;
}

.legacy-notification.legacy-notification-info,
.legacy-notification .legacy-notification-info {
  background: #eee;
  color: #333;
}

.legacy-notification.legacy-notification-error,
.legacy-notification .legacy-notification-error {
  background: #eb5757;
  color: #f2f2f2;
}

.legacy-notification.legacy-notification-success,
.legacy-notification .legacy-notification-success {
  background: #bfe7d0;
  color: #219653;
}

.legacy-notification-neutral {
  background: #eeeeee;
  color: #666666;
}

.legacy-notification-close {
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.54);
}

.legacy-notification-close:hover {
  font-weight: 500;
}
