.flyout {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;
  overflow-y: auto;
  //width: 64rem;
  max-width: calc(100vw - 3rem);
  min-width: 39rem;
  overscroll-behavior: contain;
}

.flyout-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.467rem;
  font-weight: 500;
  color: $dark-navy;
  margin-bottom: 2rem;

  .flyout-heading-close {
    cursor: pointer;
  }
}

.flyout-content {
  height: calc(100% - 4.5rem);
}

.flyout-table-container {
  max-width: 64rem;
  max-width: calc(100vw - 3rem);
}

.make-payment-flyout {
  width: 70rem;
}

.message-flyout,
.dispute-flyout,
.promise-to-pay-flyout,
.manage-auto-pay-flyout {
  width: 61rem;
}

.profile-udpate-request-flyout {
  width: 77.66rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  right: -1050px !important;
  transition: right 400ms ease-in-out;
}

.ReactModal__Content--after-open {
  right: 0 !important;
}

.ReactModal__Content--before-close {
  right: -1050px !important;
}
