.payment-method-dropdown {
  min-width: 16rem;
}

.payment-method-dropdown-menu {
  min-width: 16rem;
}

.payment-method-iframe {
  width: 832px;
  height: 100%;
  border: none;
}

.expired-label {
  color: $orange;
  font-weight: 600;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.confirmation-payment-divider {
  border-top: 1px solid $gray4;
  margin-left: -2em;
  margin-right: -2em;
  margin-bottom: 2.63rem;
}

.payment-field {
  font-size: 1.2rem;
  font-weight: 500;
}

.payment-error {
  display: flex;
  align-items: center;
  color: $red-light;
  font-size: 0.86rem;
  margin-bottom: 1rem;
  margin-top: 0.66rem;

  &__message {
    padding-left: 0.66rem;
  }
}

.manage-payment-methods-button {
  padding: 0;
  margin-bottom: 2.66rem;
  display: flex;
  align-items: center;
}
