@import "../base";

.folder-link {
  font-weight: 500;
  font-size: 24px;
  margin-right: 2.1rem;
  color: $text-default-color;

  &:hover {
    cursor: pointer;
  }

  &.active {
    color: $turquoise;
  }
}

.customer-portal-messages-table-subject {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;

  .customer-portal-messages-table-attachment-icon {
    color: $turquoise;
    padding-left: 0.6rem;
  }
}

.customer-portal-messages-table-subject-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customer-portal-messages-table-subject-body {
  color: $gray2;
  font-weight: 400;

  // Applying flex 1 to the second item in a container where both items have text-overflow ellipsis
  // will prioritize the first overflow ellipsis in the div over the second
  flex: 1;
}

.customer-portal-messages-card {
  min-height: 20vh;
}
