@import "../base";

$switch-width: 4.33rem;
$switch-height: 2rem;

$slider-diameter: $switch-height - ((0.2rem * 2) / 1rem);

/* TOGGLE SWITCH CSS */

/* Switch container - will contain the switch and the text if provided */
.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .switch:not(:last-child) {
    margin-right: 0.66rem;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
}

.switch-label {
  margin-bottom: 1rem;
  font-weight: 500;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: $slider-diameter;
  width: $slider-diameter;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $turquoise;
}

input:focus + .slider {
  box-shadow: 0 0 1px $turquoise;
}

input:checked + .slider:before {
  -webkit-transform: translateX($switch-width - $slider-diameter - 0.4rem);
  -ms-transform: translateX($switch-width - $slider-diameter - 0.4rem);
  transform: translateX($switch-width - $slider-diameter - 0.4rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
