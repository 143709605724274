@import "../base";

.lockstep-logo {
  .lockstep-logo-text {
    color: $text-default-color;

    &.inverse {
      color: $text-inverse-color;
    }
  }
}
