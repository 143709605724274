@import "../base";

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-information-card {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  .document-container {
    border: solid 1px $gray4;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 71.06rem;
  }

  .signup-heading-container {
    margin-bottom: 2rem;
    margin-top: 2rem;
    h2 {
      margin: 1rem;
    }
  }
  .button-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button-primary-square {
    margin-bottom: 2rem;
  }

  .button-container {
    button {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .accont-profile-item__content {
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      margin-top: 0.6rem;
      font-size: 1.7rem;
    }
  }
}

.login-information-card {
  display: flex;
  width: 28rem;
  justify-content: center;
  align-items: center;

  .signup-message {
    margin-top: 1.2rem;
    font-size: 0.9rem;
    span {
      color: $turquoise;
    }
  }

  .submit-button {
    margin-top: 3.33rem;
    .button-primary-square {
      width: 21.86rem;
    }
  }
}
