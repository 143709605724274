.tooltip-container {
  .dropdown {
    width: 100%;

    .lockstep-dropdown__menu {
      font-size: 0.75rem;
      padding: 0.35rem;
      background-color: $gray1;
      color: $white-background-color;
      border: none;
      box-shadow: none;
      max-width: 50%;
      margin-left: 3.33rem;
      border-radius: 3px;
    }

    button {
      padding: 0;
      width: 100%;
    }
  }
}
