.settings-flyout {
  width: 76.8rem;

  & > div {
    margin: 2.8rem 0;
    max-width: 90%;
  }

  .table-data {
    height: auto;
  }

  .flyout-heading-close {
    position: absolute;
    right: 2.1rem;
    top: 2.1rem;
    color: $turquoise;
    cursor: pointer;
  }

  .flyout-heading {
    margin-top: 0.5rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: flex-start;

    h2 {
      font-weight: 600;
      font-size: 1.33rem;
      margin-bottom: 0;
    }
  }

  &__back {
    margin-right: 1rem;
    color: $turquoise;
    cursor: pointer;
  }

  &__edit {
    color: $turquoise;
    margin-left: 0.5rem;
  }

  &__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 2.1rem 1.1rem;
    cursor: pointer;
  }

  &__rectangle {
    margin-bottom: 1.08rem;
    padding: 1.4rem 1.08rem;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }

  &__icon-wrapper {
    background-color: $gray4;
    color: $gray1;
    width: 3.46rem;
    height: 3.46rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  &__subtitle {
    font-size: 0.9rem;
  }

  &__inline-input {
    margin-bottom: 0;
  }
}

.email-types {
  td {
    padding: 0.5rem 0;
  }

  tr > td:first-child {
    font-weight: 600;
    padding-right: 2rem;
  }

  &__edit {
    color: $turquoise;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
