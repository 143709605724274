@import "../base";

.contact-email-select {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .email-select__label {
    color: $dark-navy;
  }

  .email-select__label--is-active {
    color: $turquoise;
  }

  .to-label {
    display: flex;
    justify-content: space-between;

    &__buttons {
      display: flex;
      cursor: pointer;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.email-select-container .email-select {
  &__menu {
    width: 50%;
    border: 1px solid $turquoise;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 0 2px 3px $gray4;
  }

  &__option {
    cursor: pointer;
    font-size: 0.8rem;
  }

  &__option--is-focused {
    color: $text-default-color;
    background-color: rgba($gray2, 0.08) !important;
  }

  &__option__container {
    display: flex;
    align-items: center;
  }

  &__option__user-info {
    margin-left: 1rem;
  }

  &__control {
    border: 1px solid $gray3;
    border-radius: 2px;
    box-shadow: none;
    cursor: pointer;
  }

  &__control:hover,
  &__control--is-focused {
    border: 1px solid $turquoise;
    border-radius: 2px;
    box-shadow: none;
    cursor: pointer;
  }

  &__control:active {
    border-radius: 2px;
    border: 1px solid $turquoise;
  }

  &__multi-value {
    background-color: $white-background-color;
    border-radius: 14px;
    border: 1px solid $gray4;
  }

  &__multi-value__remove {
    color: $turquoise;
  }

  &__multi-value__remove:hover {
    color: $turquoise;
    background-color: transparent;
  }
}

.email-select__multi-value.invalid-email-container {
  background-color: $red-light;
  border-color: $red-light;

  .email-select__multi-value__label,
  .email-select__multi-value__remove {
    color: $white-background-color;
  }
}
