@import "../base";

.search-preview {
  padding: 1.33rem 1rem;
  max-width: 45.4rem;

  h2 {
    font-size: 1.07rem;
    font-weight: 500;
    line-height: 1.27rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 2.66rem;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-top: 1rem;

    &:not(:last-of-type) {
      padding-bottom: 1rem;
      border-bottom: 1px solid $gray5;
    }

    &:hover {
      color: $turquoise;
      cursor: pointer;
    }
  }

  &__see-all {
    padding-top: 1.2rem;
    font-size: 1.07rem;
    font-weight: 600;
  }
}

.search-account__item,
.search-contact__item {
  padding: 1.4rem 1.07rem;
  max-width: 46.66rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.07rem;
}

.search__see-all,
.search__see-more {
  font-size: 1.07rem;
  font-weight: 600;
  padding: 0;
}

.search__see-more {
  margin-top: 2rem;
}

.search-result__account,
.search-result__contact {
  &__name {
    font-size: 1.07rem;
  }

  &__update-date {
    font-size: 0.8rem;
    color: $gray3;
  }
}

.search-bar {
  border: 1px solid $gray5;
  border-radius: 2px;
  background: $gray5;

  &:not(.active) .icon {
    color: $gray3;
  }

  &.active {
    background: none;
    border-color: $turquoise;
  }
}

.search-bar__item--history {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.93rem;
  line-height: 1.06rem;

  .icon {
    margin-right: 0.66rem;
    vertical-align: unset;
  }
}

.search-bar__dropdown__history-header {
  padding: 1.33rem 1.06rem;
  padding-bottom: 0.93rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
}
