@import "../base";

.radio-input {
  &__input {
    margin-bottom: 1.73rem;
  }
  .hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    display: none;
    margin-bottom: 1.73rem;
  }
}
