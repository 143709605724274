@import "../base";

.lockstep {
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-height: 100vh;
}

.lockstep-view {
  display: flex;
  flex-direction: column;
  background-color: $off-white-background-color;
  width: 100%;
}

.notifications-button {
  margin-top: 3.16rem;
  width: 250px !important;
}

.notifications-subtext {
  margin-bottom: 2.93rem;
}

.view-content {
  padding: 2.133rem;
  overflow-y: auto;
  flex-grow: 1;

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.73rem;

    .button-primary {
      background: $white-background-color;
      margin-right: 1.5rem;
    }
  }
}

.view-content-header {
  background-color: $white-background-color;
  padding: 0 1.06rem;
  padding-left: 2.13rem;
  border-bottom: 1px solid $gray4;

  > .view-content-header-main {
    padding-top: 1rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .user-avatar {
    height: 2.66rem;
    width: 2.66rem;
  }
}

.view-content-header-right {
  display: flex;
  align-items: center;
}

.view-content-header-links {
  display: flex;
}

.view-content-header-account,
.view-content-header-sequence,
.view-content-header-requests,
.view-content-header-myProfile,
.view-content-header-search,
.view-content-header-conversation {
  display: flex;
  align-items: center;

  .avatar {
    //TODO: Fix global avatar styling
    height: 54px;
    width: 54px;
    margin-left: 0.8rem;
  }

  h2 {
    font-weight: 600;
    font-size: 1.33rem;
    margin-bottom: 0;
    margin-left: 0.92rem;
  }

  .button-action-icon {
    margin-left: -0.53rem;
  }
}

.view-content-header-account {
  margin-bottom: 2rem;
  .display-name-header {
    display: flex;
    flex-direction: column;
    margin-left: 0.92rem;
    .display-name {
      display: flex;
      align-items: center;
    }
    h2 {
      margin-left: 0;
    }
  }
  .customer-id-display {
    color: $gray3;
  }
}

.view-content-header-sequence {
  margin-bottom: 0.2rem;
}

.view-content-header-requests {
  margin-bottom: 3rem;
}

.view-content-header-myProfile {
  margin-bottom: 1.6rem;
}

.view-content-header-search {
  margin-bottom: 2rem;
  margin-top: 1.66rem;
}

.view-content-header-conversation {
  margin-bottom: 1rem;

  &__assigned-to {
    margin-left: 0.92rem;
    font-size: 0.933rem;
    color: $gray3;
  }
}

.lockstep-side-bar {
  display: flex;
  flex-direction: column;
  background-color: $dark-navy;
  max-height: 100vh;

  > .lockstep-side-bar-logo {
    padding: 1.08rem;
    border-bottom: 1px solid $gray1;
    height: 5rem;
  }

  > .lockstep-side-bar-nav {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

.network-activity-card {
  margin-bottom: 1.08rem;
  padding: 1.4rem 1.08rem;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  .network-activity-card-description {
    font-size: 1.08rem;
    line-height: 1.38rem;
  }

  &:hover {
    border: 1px solid $turquoise;
    cursor: pointer;
    transition: 0.5s;

    .network-activity-card__button {
      display: block;
    }
  }

  &__button {
    position: absolute;
    right: 1.5rem;
    display: none;
  }
}

.network-activity-card-avatar {
  width: 2.92rem;
  height: 2.92rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: $purple;
  margin-right: 1rem;
}

.card-title {
  margin-bottom: 2.13rem;
}

.account-profile__card {
  width: 45rem;
  padding: 1.73rem;

  .account-profile__card__last-updated {
    position: absolute;
    right: 1.73rem;
    top: 1.77rem;
    font-size: 0.87rem;
  }
}

.profile__card {
  width: 45rem;
  padding: 1.73rem;
  margin: auto;
}
.account-profile-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  .account-profile-item__icon {
    min-width: 4.6rem;
    color: $gray3;
  }

  .account-profile-item__header {
    font-weight: 600;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__content.space-divs div {
    margin-bottom: 1.4rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.profile-container {
  display: flex;
  align-items: flex-start;

  .share-profile-card-centered {
    align-self: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2.5rem;
  }
}

.card-notes {
  margin-left: 1.87rem;
  padding: 1.1rem;

  .header-notes {
    margin-top: -0.2rem;
  }

  .notes-loader-container {
    height: 5rem;
  }

  .notes-empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 24rem;
    p {
      color: $purple;
      text-align: center;
      width: 14.5rem;
      margin-top: 1rem;
      font-size: 0.85rem;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -0.2rem;

    h2 {
      font-size: 0.93rem;
      font-weight: 600;
      line-height: 1.07rem;
      margin-right: 17rem;
      margin-bottom: 0;
    }
  }

  .content {
    font-size: 0.93rem;
    line-height: 1.07rem;
  }
}

.search-loader {
  margin-top: 4rem;
  margin-left: 23rem;
  height: 5rem;
}

.activity-loader {
  justify-content: center;
  margin-top: 5rem;
  min-height: 10vh;
  align-self: center;
}

.search-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  .avatar {
    height: 1.533rem;
    width: 1.533rem;
    background-color: $purple;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;
  }
}

.account-contacts-view-empty,
.account-documents-view-empty {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  h2 {
    margin-top: 1.8rem;
    color: $purple;
    font-weight: 600;
    font-size: 1.33rem;
  }

  p {
    color: $purple;
    font-size: 1.2rem;
    max-width: 25.6rem;
    text-align: center;
    margin-bottom: 2.66rem;
  }
}

.notes-button-container {
  display: flex;
  justify-content: center;
}

.note-container {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;

  p {
    margin: 0;
    padding: 0;
  }

  .note-body {
    font-size: 0.9rem;
    line-height: normal;
    max-width: 20.9rem;
    min-width: 20.9rem;
  }
  .note-subtext {
    font-size: 0.73rem;
    color: $gray3;
    margin-top: 0;
  }
  .note-button-container {
    display: flex;

    div {
      margin-right: 0.5rem;
    }
  }
}

.lockstep-verified-check-container {
  display: flex;
  align-items: center;
}

.lockstep-verified-checkmark-icon {
  color: $purple;
  margin-left: 0.5rem;
}

.lockstep-verified-checkmark-container {
  .lockstep-dropdown__menu {
    transform: translate(0.8rem, 1.66rem) !important;
  }
  .lockstep-verified-checkmark-icon {
    margin: 0;
  }
  .lockstep-verified-dropdown-content {
    width: 16.2rem;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
}

.network-table-hover-icon-container {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  color: $turquoise;
  padding: 0;

  .tooltip-container {
    min-width: 2.75rem;
    .dropdown .lockstep-dropdown__menu {
      margin-left: 0rem;
    }
  }

  &__disabled {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    color: $gray4;
    padding: 0;
  }

  .hover-icon-disabled {
    width: 40px;
    height: 40px;
    padding: 0 0.63rem;
    color: $gray4;
  }
}

.status-indicator-container {
  display: flex;
  align-items: center;
  .status-indicator-circle__ {
    margin-left: 0.5rem;
    background-color: gray;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50px;

    &Open {
      @extend .status-indicator-circle__;
      background-color: $orange;
    }

    &Unopened {
      @extend .status-indicator-circle__;
      background-color: $red;
    }

    &Clicked {
      @extend .status-indicator-circle__;
      background-color: $green;
    }

    &Closed {
      @extend .status-indicator-circle__;
      background-color: $gray4;
    }
  }
}

.archived-badge-container {
  .lockstep-dropdown__menu {
    transform: translate(1.35rem, 1.66rem) !important;
  }
  .lockstep-verified-dropdown-content {
    width: 16.2rem;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
}

.private-badge-container {
  .badge-private {
    margin-left: 0;
  }
  .lockstep-dropdown__menu {
    transform: translate(0.7rem, 1.66rem) !important;
  }
  .lockstep-verified-dropdown-content {
    width: 16.2rem;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
}

.missing-email-address-container {
  color: $red;
  cursor: pointer;
  display: flex;
  span {
    margin-left: 0.5rem;
  }
}

.add-contacts-flyout {
  h2 {
    font-weight: 600;
    font-size: 1.33rem;
  }

  .first-time-form-card {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    width: 60.13rem;
  }

  .contact-update-autofill-container {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.06rem;
      margin-bottom: 0.66rem;
      font-weight: 500;
    }

    button {
      width: 23.2rem !important;
      height: 2.4rem;
      border: 1px solid $gray7;
      border-radius: 2px;
      padding: 0.4rem;
    }

    .lockstep-dropdown__menu {
      width: 23.2rem !important;
    }
  }
  .flyout-heading-close {
    position: absolute;
    right: 2.1rem;
    top: 2.1rem;
    color: $turquoise;
    cursor: pointer;
  }
}

.heading-container {
  display: flex;
  flex-direction: column;
  .name-badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    h2 {
      margin-left: 0.92rem;
    }
  }

  .contacts-subtext {
    margin-top: -0.4rem;
    .contacts-table-subtext {
      margin-left: 0.92rem;
    }
  }
}

.add-contact-button-container {
  margin-bottom: 2rem;
  .icon {
    margin-right: 0.5rem;
  }
  .table-data-card-header-buttons {
    .button-primary {
      margin-top: 0;
    }
  }
}

.email-template-picker {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.conversation-editor__template-loader {
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-view-documents-dropdown-container {
  .lockstep-dropdown__menu {
    transform: translate(-8rem, 2.8rem) !important;
  }

  .account-view-documents-dropdown {
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    min-width: 11.66rem;

    span {
      margin: 0.2rem;
      color: $turquoise;
      cursor: pointer;
    }
  }
}

.send-profile-request-tooltip-container {
  &:hover .send-profile-request-tooltip {
    display: block;
  }

  .send-profile-request-tooltip {
    background-color: $gray2;
    text-align: center;
    color: $white-background-color;
    font-size: 0.73rem;
    position: absolute;
    display: none;
    padding: 0.25rem 0.4rem;
    margin: 0.5rem -1rem;
    border-radius: 3px;
  }
}

.display-outgoing-email-test-modal {
  margin-left: 5.4rem;
}

.download-link {
  color: $link-color;
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
  }
}
// default for svg library (override this class to change the size of the svg components in your context)
.svg-container {
  width: 10rem;
  height: 6.06rem;
}

.delete-button-container {
  button {
    background-color: $red;

    &:hover {
      background-color: $red;
    }
  }
}


.btn-group .btn.btn-secondary.dropdown-toggle{
  &:hover{
    background-color: grey;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: grey;
}