.pending-payment-tooltip {
  background: white;
  border-radius: 5px;
  border: 1px solid #DBDBDB;
  font-size: 0.80rem;
  padding: 8px 10px;
  width: 200px;
}

.pending-payment-tooltip-header {
  margin-bottom: 4px;
}

.pending-payment-tooltip-entry {
  display: flex;
  flex-direction: column;
  margin-top: 6px;  
}

.pending-payment-tooltip-row {
  display: inline-flex;
  font-weight: 600;
  justify-content: space-between;
}

.tippy-arrow {
  visibility: hidden;
}

.tippy-arrow, .tippy-arrow::before {
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 1px;
  height: 9px;
  position: absolute;
  width: 9px;
  z-index: 1;
}

.tippy-arrow::before {
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}

.tippy-arrow, .tippy-arrow::after {
  background-color: #FFFFFF;
  border-style: none;
  height: 9px;
  position: absolute;
  width: 9px;
  z-index: 1;
}

.tippy-arrow::after {
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: -4px;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: -4px;
}

.tippy-box[data-placement^='left'] > .tippy-arrow {
  right: -4px;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: -4px;
}

// These styles provide the shift-away animation but aren't included in @tippyjs/react by default
.tippy-box[data-animation=shift-away][data-state=hidden]{opacity:0}
.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=top]{transform:translateY(10px)}
.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=bottom]{transform:translateY(-10px)}
.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=left]{transform:translateX(10px)}
.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=right]{transform:translateX(-10px)}
