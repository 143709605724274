@import "../base";

.communication-template-input {
  width: 20rem;
  margin-bottom: 2rem;
}

.communication-template-divider {
  margin-top: 0.66rem;
  margin-bottom: 2.66rem;
}

.communication-template-attachment-name {
  user-select: none;
  &:hover {
    cursor: pointer;
  }
}
