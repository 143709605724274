@import "../base";
@import "./main";

.profile-update-form-button-container {
  align-self: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  button {
    margin: 0 1rem;
  }
}

.profile-update-view-header {
  width: 100%;
  background-color: $white-background-color;
  display: flex;

  &.__nav-bar {
    border-bottom: 1px solid $gray4;
    flex-direction: column;
  }

  .profile-update-view-header-container {
    display: flex;
  }

  &.__share-contacts {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .profile-update-header-checkbox-container {
    margin-top: 0.1rem;
    align-self: center;
  }

  .profile-update-header-links {
    margin-left: 2.13rem;
    margin-top: 1.25rem;
  }

  .profile-update-view-header-picture-container {
    align-self: center;
    margin-left: 2.13rem;
    margin-right: 1.1rem;
    margin-top: 0.5rem;
  }
}

.share-profile-container {
  align-self: center;
}

.profile-share-view-documents-container {
  width: 71.2rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2.66rem;

  &__account-view {
    margin-right: auto;

    h2 {
      font-size: 1.35rem;
      font-weight: 500;
      line-height: 1.66rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.profile-update-header-logo-box {
  border-radius: 0.33rem;
  margin-top: 1.33rem;
  margin-right: 1.35rem;
  margin-left: 1.35rem;
  justify-content: center;
  align-items: center;
  max-width: 8rem;
  max-height: 4rem;

  img {
    object-fit: cover;
    height: 100%;
    object-position: center;
    width: 100%;
  }
}

.profile-update-header-text-box {
  margin-top: 1.6rem;

  &__no-logo {
    @extend .profile-update-header-text-box;
    margin-left: 2rem;
  }
}

.profile-update-header-text-box h2 {
  font-size: 1.35rem;
  font-weight: 600;
  line-height: 1.66rem;
}

.profile-update-header-text-box p {
  margin-top: 0.4rem;
  color: $gray3;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.profile-update-header-lockstep-box {
  position: absolute;
  right: 2.13rem;
  top: 1.33rem;
}

.profile-update-header-lockstep-box span {
  font-size: 0.66rem;
  font-weight: 500;
  line-height: 0.73rem;
}

.profile-udpate-card-table-container {
  width: 71.2rem;
  display: flex;
  align-self: center;
}

.table-heading-row {
  padding: 0.9rem 0.7rem;
  font-size: 0.93rem;
  line-height: 1.06rem;
}

.profile-update-card-table-name {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  &__account-view {
    font-weight: 400;
  }
}

.profile-update-card-table-documentpadding {
  padding-top: 1.33rem;
  padding-bottom: 1.33rem;
}

.profile-update-done-icon-container {
  width: 19.73rem;
  height: 12.4rem;
  margin-top: 1.33rem;
  background-color: $gray6;
  margin-right: auto;
  margin-left: auto;

  .svg-container {
    width: 19rem;
    height: 10rem;
  }
}

.profile-update-header-logo {
  display: flex;
  align-self: center;
  margin-left: 1.73rem;
}

.profile-update-card-form-checkbox {
  height: 1.2rem;
  width: 1.2rem;
  align-self: center;
  margin-right: 0.66rem;
  margin-bottom: 2.4rem;
}

.profile-update-card-form-checkbox-label {
  font-size: 1.06rem;
  font-weight: 500;
  line-height: 1.26rem;
  align-self: center;
}

.add-contacts-button-adjustment {
  margin-bottom: 1.5rem;
}

.profile-update-contact-delete-button {
  box-sizing: border-box;
  height: 3rem;
  width: 7.4rem;
  border: 0.06rem solid $turquoise;
  border-radius: 1.6rem;
  background-color: $white-background-color;
  color: $turquoise;
  font-size: 0.93rem;
  font-weight: 500;
  line-height: 1.06rem;
  margin: 0rem;
}

.profile-update-contact-delete-button span {
  margin-left: 0.66rem;
  align-self: center;
}

.profile-update-form-mainloader {
  margin-top: 20rem;
}

.contact-form-dropdown {
  position: absolute;
  padding-top: 0;
  padding-bottom: 0;
  top: 0;
  right: 3rem;
}

.profile-update-header-logo {
  align-self: center;
  margin-top: 1.33rem;
  margin-bottom: 1.33rem;
}

.profile-udpate-position-country-line {
  margin-bottom: 2rem;
}

.profile-update-button-container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;

  button {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.profile-update-small-button-container {
  width: 15.3rem;
  margin-right: auto;
  margin-top: 3rem;
  margin-left: auto;
}

.profile-udpate-remove-contact-adjustment {
  margin-top: 1.7rem;
}

.profile-update-color-picker-container {
  margin-left: 8.66rem;
  position: absolute;
  top: 34.6rem;
  left: 20.5rem;

  p {
    margin-bottom: 0.1rem;
  }

  .sub-text {
    font-size: 11px;
    margin: 0.1rem;
    color: $gray3;
  }

  .profile-update-form-color-picker {
    width: 3.33rem;
    height: 3.33rem;
  }

  .color-picker-position {
    position: absolute;
    top: 7rem;
    left: -4rem;
    z-index: 10;
  }
}
.contacts-table-disabled {
  color: $gray3;
}

.profile-invariant-container {
  display: flex;
  align-content: center;
  min-width: 23.2rem;

  .input-label {
    width: auto;
  }

  button {
    margin-top: -0.05rem;
  }

  .dropdown-menu.show {
    font-size: 0.8rem;
    min-width: 15rem;
    padding: 0.5rem;
  }
}

.profile-update-invariant-field {
  font-weight: 500;
  margin-top: 0.5rem;

  &__icon {
    color: $turquoise;
    margin-top: -0.3rem;
  }
}

.profile-update-dropdown-content {
  padding: 0.5rem;
  min-width: 20rem;
}

.profile-update-card-container {
  margin-top: 1rem;
  align-self: center;

  .profile-update-card-avatar-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-weight: 600;
      font-size: 1.3rem;
      margin-left: 0.8rem;
    }
  }
}

.profile-update-form-edit-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  button {
    margin: 0.5rem 1rem;
    min-width: 20rem;
  }
}

.contacts-table-subtext {
  font-size: 0.8rem;
  color: $gray3;
  font-weight: 500;
}

.profile-update-success-container-verified {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 3rem;
  }
}

.success-page-celebrate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem;
  a {
    color: $white-background-color;
  }
  .svg-container {
    width: 16.2rem;
    height: 20rem;
  }
  .text-container {
    h2 {
      font-size: 1.86rem;
    }
    width: 68.73rem;
    text-align: center;
    margin-bottom: 3rem;
  }
}
