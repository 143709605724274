@import "../base";

.avatar {
  position: relative;

  &__image {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
  }

  &__empty {
    background-color: $purple;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-inverse-color;

    &__text {
      width: 100%;
      font-weight: 600;

      text {
        width: 100%;
        line-height: 1em;
        fill: $text-inverse-color;
      }
    }

    &--account {
      background-color: $orange;
    }

    &--contact {
      background-color: $purple;
    }

    &--current-user {
      background-color: $gray1;
    }

    &--perspective {
      background-color: $gray2;
    }
  }
}
