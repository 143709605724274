@import "../base";

.conversation-thread-item {
  padding: 1.533rem 0;
  /* border-top: 1px solid #dddddd; */
  border-top: 1px solid $gray4;
  display: flex;

  .avatar {
    margin-right: 1rem;
  }

  &__body {
    flex-grow: 1;
    padding-right: 1rem;

    &__poster {
      font-weight: 500;
      padding: 0;
    }

    &__poster__menu {
      padding: 0.66rem 0.82rem;
      white-space: nowrap;

      &__name {
        font-weight: 500;
        font-size: 0.93rem;
      }

      &__email {
        padding-top: 0.2rem;
        font-size: 0.93rem;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__container:hover {
        cursor: pointer;
      }

      &__to,
      &__cc {
        font-size: 0.8rem;
        color: $gray3;
      }
    }

    &__content {
      margin-top: 0.53rem;

      &--minimized div {
        max-width: 60rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__badge {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
      border-radius: 3px;

      &--assigned {
        background-color: $purple;
        color: $text-inverse-color;
      }

      &--status {
        background-color: $gray3;
        color: $text-inverse-color;
      }

      &--snoozed {
        background-color: $eggplant;
        color: $text-inverse-color;
      }

      &--forward {
        background-color: $plum;
        color: $text-inverse-color;
      }
    }
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.conversation-thread-forward-badge-container {
  display: flex;
  align-items: center;
  .view-link {
    margin-left: 1rem;
    color: $turquoise;
    cursor: pointer;
  }
}

.conversation-thread-full-message {
  margin-top: 1rem;
}
