@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700");

@import "base";

@import "framework";

@import "components/card";
@import "components/table-data";
@import "components/dropdown";
@import "components/buttons";
@import "components/full-screen-spinner";
@import "components/html-editor";
@import "components/flyout";
@import "components/user-avatar";
@import "components/notifications";
@import "components/text-input";
@import "components/lockstep-logo";
@import "components/icon-spinner";
@import "components/auto-complete-input";
@import "components/checkbox";
@import "components/radio-input";
@import "components/toggle-switch";
@import "components/language-selector";
@import "components/avatar";
@import "components/expandable-nav-link";
@import "components/filter-builder";

@import "components/make-payment-flyout";
@import "components/flyouts/disputes-flyout";

@import "pages/site-down";

@import "customer-portal/main";
@import "customer-portal/statements";
@import "customer-portal/contact-info";
@import "customer-portal/messages";
@import "customer-portal/payments";
@import "customer-portal/auto-pay-indicator";
@import "customer-portal/manage-auto-pay-flyout";
@import "customer-portal/open-invoices";

@import "manage/sequences";
@import "manage/customer-groups";
@import "manage/communication-templates";
@import "manage/test-email-config-modal";

@import "lockstep/main";
@import "lockstep/perspectiveSelector";
@import "lockstep/viewSelector";
@import "lockstep/profileUpdateView";
@import "lockstep/firstTimeUser";
@import "lockstep/profileUpdateFlyout";
@import "lockstep/settingsFlyout";
@import "lockstep/search";
@import "lockstep/profileManagement";
@import "lockstep/signup";
@import "lockstep/conversation-action-buttons";
@import "lockstep/conversation-thread-item";
@import "lockstep/conversation-editor";
@import "lockstep/tooltip";
@import "lockstep/contact-email-select";
@import "lockstep/summary-email-preferences";
@import "lockstep/conversion-page";
@import "lockstep/add-perspective-modal";
@import "lockstep/summary-count-badge";

/* UNAUTHORIZED PAGES */
.unauthorized-header-container {
  padding: 1em 0;
  background-color: #0f3044;
}

/* APPROVAL REQUEST */

.approval-request {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;
}

.approval-request-subject {
  padding: 0 1em;
  max-width: 900px;
  padding-top: 10vh;
  text-align: center;
}

.approval-request-body {
  align-content: center;
  width: 100%;
  max-width: 550px;
  padding-top: 1.4em;
}

.approval-request-powered-by {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 2rem;
}

.approval-request-bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
}

/* Other stuff */

.lockstep-logo {
  text-align: center;
}

#root {
  height: 100%;
}

.light {
  color: #ffffff;
}

.nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 2px solid #00c9b2;
}

.nav-tabs .nav-link {
  border: none;
}

.navbar.navbar-light ul li {
  color: #ffffff;
  margin-bottom: 2px;
}

.isActiveLink {
  background-color: #00c9b2;
  color: #0f3044;
}

.perspective-tabs {
  border: none;
}

ul.nav-tabs li.nav-item.first {
  margin-left: -0.8rem;
}

ul.nav-tabs li.nav-item {
  margin-left: 0.8rem;
}

ul.nav.nav-tabs>li.nav-item>a.active,
ul.nav.nav-tabs>li.nav-item>div.active {
  color: #08ada2;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #00c9b2;
}

ul.nav.nav-tabs>li.nav-item>a,
ul.nav.nav-tabs>li.nav-item>div {
  color: #666666;
}

.main-nav {
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  border-right: black 1px solid;
  min-height: 100%;
  width: 25%;
  max-width: 305px;
  background-color: #0f3044;
  padding: 0;
}

.main-nav a {
  font-size: 1em;
  display: block;
}

.main-nav a:hover {
  text-decoration: none;
}

.main-nav ul {
  display: block;
  width: 100%;
}

.grants ul li {
  display: block;
  height: 100%;
}

.grants ul {
  display: block;
}

.blue-font-dark-bg {
  color: #3da0fb;
}

a.blue-font-dark-bg:hover {
  color: #51acff;
}

.blue-font-white-bg {
  color: #097abf;
}

a.blue-font-white-bg:hover {
  color: #3da0fb;
}

.cancel-link-white-bg {
  color: #999999;
  cursor: default;
}

.cancel-link-white-bg:hover {
  color: #666666;
}

.green-link-white-bg {
  color: #03a08f;
  cursor: default;
}

.green-link-white-bg:hover {
  color: #08ada2;
}

.green-link-white-bg.active {
  color: #0f3044;
  text-decoration: none;
}

.green-link-white-bg.disabled {
  color: #666;
  cursor: default;
}

.blue-link-white-bg {
  cursor: pointer;
  color: #097abf;
  text-decoration: none;
}

.blue-link-white-bg:hover {
  color: #3da0fb;
  text-decoration: none;
}

.compose-button {
  width: 170px;
  height: 33px;
  color: #fff;
  background: $purple;
  font-size: 1em;
  line-height: 1em;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  margin-left: 5%;
  width: 90%;
  transition: background-color 0.3s ease-in-out;
}

.compose-button:hover {
  background-color: $dark-purple;
}

.compose-button:disabled,
.compose-button:hover:disabled {
  background-color: rgba(9, 122, 191, 0.3);
  color: #999999;
  cursor: not-allowed;
}

.button-green-secondary {
  min-width: 100px;
  font-size: 1.06rem;
  font-weight: 500;
  padding: 7px 15px;
  color: #ffffff;
  background-color: #08ada2;
  border: 1px solid #08ada2;
}

.button-green-secondary:disabled {
  background-color: rgba(31, 182, 193, 0.5);
    cursor: not-allowed;
}

.button-green-secondary:hover:not(:disabled) {
  background-color: #08b8ad;
  border-color: #08b8ad;
}

.button-warning {
  font-weight: 500;
  color: #fe696a;
  background-color: #fff;
  border: 1px solid #fe696a;
  min-width: 4.5em;
  height: 2.8em;
}

.button-warning:hover {
  color: #fff;
  background-color: #fe696a;
}

.button-warning-secondary {
  min-width: 100px;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 15px;
  color: #fe696a;
  background-color: #fff;
  border: 1px solid #fe696a;
}

.button-warning-secondary:hover {
  color: #fff;
  background-color: #fe696a;
}

.button-blue-secondary {
  min-width: 100px;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 15px;
  color: #2d9cdb;
  background-color: #fff;
  border: 1px solid #2d9cdb;
}

.button-blue-secondary:hover {
  color: #fff;
  background-color: #2d9cdb;
}

.compose-button-white-bg {
  width: 120px;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 5px;
  background: #097abf;
  border-radius: 2px;
}

.compose-button-white-bg:hover {
  background: #1696e4;
}

.onboarding-button {
  padding: 10px 20px;
  font-weight: 500;
  background-color: #02b7a2;
  color: #fff;
}

.onboarding-button:hover {
  background-color: #00c9b2;
}

.onboarding-button:disabled {
  background: #ccc;
}

.onboarding-cancel-button {
  color: #666;
  background-color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  margin-right: 10px;
}

.onboarding-cancel-button:hover {
  background: #f1f1f1;
}

.onboarding-cancel-button:hover:disabled {
  background: #fff;
}

.lockstep-button {
  cursor: pointer;
  padding: 0.35em 1em;
  font-size: 0.85em;
  border-radius: 2px;
  background-color: #dddddd;
  color: #444444;
}

.lockstep-button:hover {
  background-color: #cccccc;
}

.loginModal div {
  background-color: #0f3044;
  color: #ffffff;
}

.turquoise-link {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 19px;
  color: $turquoise;
}

.modal-dialog .signUpModal {
  padding: 0;
  margin: 0;
  overflow: auto;
}

.row .vcenter {
  align-items: center;
}

input:-webkit-autofill {
  box-shadow: inset 0 0 0px 9999px white !important;
  -webkit-box-shadow: inset 0 0 0px 9999px white !important;
}

.fa-spinner {
  /* border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px; */
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.original-email-modal {
  position: absolute;
  left: 320px;
  top: 20px;
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;
}

.send-statement-modal {
  position: absolute;
  left: calc(50% - 343px);
  top: 20px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;
}

.primary-contact-archive-modal {
  position: absolute;
  left: calc(50vw - 38.86rem);
  top: calc(25vh - 100px);
  width: 77.73rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;

  .header {
    font-weight: 500;
  }

  .document-container {
    border: solid 1px $gray4;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      min-width: 14rem;
    }
  }
}

.custom-modal,
.custom-modal-wide,
.custom-modal-safari {
  position: absolute;
  left: calc(50vw - 250px);
  top: calc(25vh - 100px);
  width: 500px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;
}

.custom-modal-safari {
  left: calc(50vw - 300px);
  width: 650px;
}

.custom-modal-header-safari {
  display: flex;
  font-size: 1.1rem;
  font-weight: 500;
  color: #0f3044;
  width: 500px;

  &--small {
    font-size: 1.07em;
    font-weight: 500;
    color: $text-default-color;
  }

  &__close {
    color: $link-color;

    &:hover {
      cursor: pointer;
      color: $link-hover-color;
    }
  }
}

.modal-buttons-safari {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.button-green-safari {
  font-weight: 500;
  background-color: #08ada2;
  color: #ffffff;
  width: 13.1rem;
  height: 2.8em;
}

.custom-modal-wide {
  left: calc(50vw - 300px);
  width: 600px;
}

.custom-modal-dkim {
  position: absolute;
  top: calc(25vh - 100px);
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 2em;
  max-height: 60vh;
  overflow-y: auto;
  &.sm{
    width: 35vw;
  }
  &.md{
    width: 60vw;
  }
  &.lg{
    width: 90vw;
  }
}

.custom-date-modal {
  height: 476px;
  width: 400px;
}

.date-picker-container {
  height: 284px;
}

.custom-modal-overlay,
.flyout-overlay {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(102, 102, 102, 0.2);
}
.embed-widget-height{
  height: 100%;
}

.custom-modal-header {
  font-size: 1.6em;
  font-weight: 700;
  color: #0f3044;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--small {
    font-size: 1.07em;
    font-weight: 500;
    color: $text-default-color;
  }

  &__close {
    color: $link-color;

    &:hover {
      cursor: pointer;
      color: $link-hover-color;
    }
  }
}

.custom-modal-divider {
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #dddddd;
  margin: 0.6em 0em;
}

.action-modal-company {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  font-size: 1.2em;
}

.modal-buttons {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.modal-warning-text {
  color: #fe696a;
}

.confirm-password-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .scroll-box {
    margin-top: 1rem;
  }
}

.error-text {
  text-align: center;
  color: $red;
  margin-bottom: 0.5rem;
}

.confirm-password-container {
  display: flex;
  width: 28rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .error-text {
    text-align: center;
    color: $red;
    margin-bottom: 0.5rem;
  }

  .confirm-password-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
  }

  .pw-toggle {
    color: $turquoise;
    margin-top: 0.5rem;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;

    .checkbox {
      margin-right: 0.5rem;
      margin-left: 1rem;
    }
  }
}

/* LOGIN PAGE CSS */
.login-container {
  position: relative;
  background-color: #0f3044;
  height: 100vh;

  .login-container-logo {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }
}

.login-title {
  margin-bottom: 0.8em;
}

#login-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: #ffffff;
  width: 50%;
  max-height: calc(100vh - 110px);
  padding: 2em;
  margin: 0 auto;
}

.reset-login {
  max-width: 600px;
  color: #999999;
}

#login-left-container {
  float: left;
  width: 47.5%;
  padding-right: 2em;
  padding-top: 5px;
  padding-bottom: 0;
}

.login-blurb {
  font-size: 1.25em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #dddddd;
}

#login-right-container {
  float: right;
  width: 47.5%;
  padding-top: 5px;
  padding-left: 2em;
  padding-bottom: 0;
  color: #999999;
}

#login-right-container input .form-control {
  border-color: #999999;
  color: #999999 !important;
}

#login-save-signin-container {
  text-align: left;
  padding-bottom: 10px;
}

.login-login-btn {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.login-forgot-password {
  display: inline-block;
  padding-top: 10px;
  text-align: center;
}

#login-login-footer {
  padding: 0;
}

#login-signup-container {
  margin: 0;
  padding-top: 100px;
}

#login-signup-link {
  color: #00c9b2;
}

.sign-up-message {
  color: #fff;
  text-align: center;
  margin-bottom: 16px;
}

.headerDivider {
  display: inline-block;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  margin: 0 calc(2.5% - 1px);
  width: 0.025%;
  height: 370px;
}

.terms-checkbox {
  display: inline-block;
  margin-right: 5px;
  height: 1em;
  vertical-align: middle;
}

.auto-complete-input-inline {
  display: flex;
  align-items: center;
}

.auto-complete-dropdown {
  border: 1px solid #ddd;
}

.auto-complete-dropdown input[type="text"] {
  border: none;
}

.auto-complete-dropdown input[type="text"]:disabled {
  background: #fff;
  font-style: italic;
  color: #666;
}

.auto-complete-dropdown .select-text {
  cursor: default;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  padding-left: 8px;
}

/* MAIN CONTENT SECTION */
.date-picker-input {
  width: 9rem;
  border: 1px solid $gray2;
  border-radius: 2px;
  padding: 0.4rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.date-picker-input:focus {
  border-color: $turquoise;
}

.date-picker-calendar .react-datepicker__day--selected:hover,
.date-picker-calendar .react-datepicker__day:hover {
  background-color: #eee;
  color: $turquoise;
  border-radius: 50%;
}

.date-picker-calendar .react-datepicker__day--selected {
  background-color: $turquoise;
  border-radius: 50%;
}

/* SEARCH VIEW SECTION */

.search-icon {
  color: #999;
  font-size: 22px;
  margin-left: 14px;
}

.search-icon:hover {
  color: #00c9b2;
  text-decoration: none;
}

.search-modal {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  background: #fff;
  z-index: 3;
}

.search-modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: #ddd;
  opacity: 0.5;
}

.search-table-row {
  width: 100%;
  display: inline-block;
  font-size: 13px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  cursor: pointer;
}

.search-table-row:hover {
  background: #f2f2f2;
}

.search-table-row .table-row-main-line {
  margin-top: 10px;
  margin-bottom: 8px;
}

.search-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #ddd;
}

.search-filter-title {
  color: #666666;
  margin-bottom: 1em;
}

.main-search-bar.focused {
  border-color: #2d9cdb;
}

.main-search-bar input {
  vertical-align: top;
  width: 412px;
  /* border: 0; */
  border: 1px solid #ccc;
  border-right: 0;
}

.main-search-bar input:focus {
  border-color: #2d9cdb;
}

.main-search-bar .fa-search {
  font-size: 18px;
  line-height: 32px;
  height: 34px;
  color: #fff;
  background-color: #08b8ad;
  padding-top: 1px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.search-modal-header h3 {
  width: calc(25vw - 34px);
  max-width: 270px;
}

.search-modal-sidebar {
  padding: 24px;
  width: calc(25vw - 10px);
  max-width: 295px;
  border-right: 2px solid #ddd;
  display: inline-block;
}

.search-modal-content {
  flex: 1;
}

.close-original-email {
  float: right;
  text-decoration: none;
  font-size: 68px;
  color: #999;
  margin-top: -12px;
}

.close-original-email:hover {
  color: #666;
}

.close-search-modal {
  float: right;
  text-decoration: none;
  font-size: 68px;
  color: #999;
  margin-top: -12px;
}

.close-search-modal:hover {
  text-decoration: none;
  color: #fe696a;
}

.main-content-body {
  padding: 15px;
}

#with-side-nav-content {
  height: 100%;
  min-height: 100%;
  min-width: calc(100% - 305px);
  width: 75%;
  vertical-align: top;
  display: inline-block;
  position: relative;
  background-color: $white-background-color;
}

.toast {
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 0;
}

.toast-show {
  visibility: visible;
  opacity: 1;
}

/* VENDOR VIEW SECTION */

.badge {
  font-weight: 400;
  border-radius: 2px;
  border: solid 1px transparent;
  color: #ffffff;
  font-size: 13px;
  padding: 2px 4px;
  min-width: 26px;
}

.badge-seagreen {
  border-color: #03a08f;
  background-color: #03a08f;
}

.badge-private {
  color: $white-background-color;
  background-color: $gray7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  border-radius: 2px;
  font-weight: 600;
  margin-left: 0.7rem;
  padding: 0 0.2rem;

  span {
    margin: 0.1rem 0.3rem 0.05rem 0.3rem;
    text-align: center;
  }
}

.badge-archived {
  color: $white-background-color;
  background-color: $dark-navy;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  border-radius: 2px;
  font-weight: 600;
  margin-left: 0.7rem;
  padding: 0 0.2rem;

  span {
    margin: 0.1rem 0.3rem 0.05rem 0.3rem;
    text-align: center;
  }
}

.badge-coral {
  border-color: #ef3c6b;
  background-color: #ef3c6b;
}

.badge-transparent {
  background-color: transparent;
}

.badge-white-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $pink;
  min-width: 2.2rem;
  color: #fff;
  height: 1.2rem;
}

.badge-reminder {
  border-color: #f7831b;
  background-color: #f7831b;
}

.badge-approved {
  background-color: rgba(0, 201, 178, 0.3);
  color: #04a895;
}

.badge-rejected {
  background-color: rgba(254, 105, 106, 0.3);
  color: #fe696a;
}

.badge-out-for-approval {
  background-color: #fce1c9;
  color: #f7831b;
}

.badge-active {
  background-color: #bfe7d0;
  color: #219653;
}

.badge-in-progress {
  background-color: #d5ebf8;
  color: #2d9cbd;
}

.badge-closed {
  background-color: #dddddd;
  color: #444444;
}

.badge-spam {
  background-color: #fe696a;
  color: #ffffff;
}

.badge-note {
  background-color: $yellow;
  color: $text-inverse-color;
  font-size: 0.6rem;
  font-weight: 600;
}

.conversation-status-badge {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3px;
  border-radius: 8px;
  padding: 4px 16px;
}

input.plain {
  border-style: solid;
  border-color: #dddddd;
}

input.thin {
  border-width: 1px;
}

input.notop {
  border-top-style: none;
  border-top-width: 0px;
}

.btn.btn-attachment {
  background-color: white;
  color: black;
  border: 1px solid;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.btn.btn-attachment a {
  background-color: white;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.icon-dropdown {
  font-size: 0.5em;
  padding-left: 0.2em;
}

.menu-icon {
  display: flex;
  justify-content: center;
  vertical-align: 30%;
  width: 30px;
  padding-right: 16px;
}

.menu-icon .new-company-icon {
  margin-left: 6px;
}

.cta span {
  padding-left: 6px;
  color: #00c9b2;
  transition: all ease-in-out 300ms;
}

.cta {
  margin-top: 3px;
  cursor: pointer;
  padding: 12px 18px;
  font-weight: 500;
  border-radius: 18px;
  border: 1px solid;
  color: #00c9b2;
  background-color: #4b4e61;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 300ms;
}

.cta:hover {
  box-shadow: 0px 37px 20px -15px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -4px);
  color: #ffffff;
  background-color: #00c9b2;
}

.cta:hover span {
  color: #ffffff;
}

.sidebar-link {
  line-height: 1.5em;
  padding: 0.3em 1em;
}

.sidebar-link span {
  color: #ffffff;
}

.sidebar-link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-link-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-text-container {
  display: flex;
  align-items: center;
  max-width: 75%;
}

.sidebar-link:hover,
.sidebar-link.active {
  background: #4b4e61;
}

.sidebar-link .badge {
  margin-top: 1px;
}

.sidebar-link.disabled {
  cursor: not-allowed;
}

.sidebar-link.disabled a,
.sidebar-link.disabled span {
  color: #c0c0c0;
}

#menu-logo {
  display: inline-block;
  margin-bottom: 12px;
  margin-left: -1px;
}

.menu-toggle {
  cursor: default;
}

.menu-toggle a {
  color: #000000;
}

.menu-toggle a span {
  color: inherit;
  padding-right: 6px;
  min-width: 26px;
}

#menu-top {
  padding: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

#menu-top h4 {
  display: inline-block;
  margin-bottom: 3px;
  font-weight: 500;
}

#menu-top h5 {
  font-size: 17px;
  margin-bottom: 6px;
  font-weight: 500;
}

.search {
  text-align: center;
  margin: 0 1rem;
  margin-right: 0.9rem;
  margin-bottom: 8px;
  display: flex;
}

.search input {
  background: #4b4e61;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  height: 35px;
  padding-left: 11px;
  vertical-align: top;
  width: 100%;
}

.search span {
  cursor: pointer;
  background: #4b4e61;
  padding-right: 10px;
  line-height: 35px;
  height: 35px;
}

#sortby-dropdown {
  border-bottom: 1px solid #5e6072;
  font-size: 13px;
  margin: 0 1rem;
  margin-bottom: 10px;
  margin-right: 0.9rem;
  padding-bottom: 4px;
}

#sortby-dropdown .fa-angle-down {
  float: right;
  line-height: 22px;
  padding-right: 5.5px;
}

.fa-angle-down,
.fa-angle-up {
  padding-left: 6px;
}

.scroll-box {
  height: calc(100vh - 290px);
}

/* OVERRIDE BOOTSTRAP CSS */

.modal-dialog.v-center-modal {
  max-width: 600px;
  padding-top: 10%;
}

.v-center-modal>.modal-content {
  border-radius: 0;
}

/* Quill Css */

.ql-toolbar,
.ql-snow,
.ql-editor {
  border: 0 !important;
}

.ql-toolbar button {
  float: none !important;
  width: auto !important;
}

.ql-toolbar button.dropdown-item:hover {
  color: #03a08f !important;
}

.ql-snow .button-blue:hover {
  color: #fff !important;
}

.quill-dropzone-container {
  position: relative;
}

.read-only-quill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2;
}

.quill-dropzone-overlay {
  color: #666;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 20;
  border: 4px dotted #666;
  top: 50px;
  bottom: 5px;
  left: 10px;
  right: 5px;
}

.quill-actions {
  border-bottom: 1px solid #cccccc;
}

.editor-action {
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  margin: 8px;

  .dropdown {
    border: none;
    padding: 0;
  }
}

.editor-action-label {
  padding-left: 5px;
}

.orange-left .ql-editor {
  border-left: 5px solid #f7831b !important;
}

.teal-left .ql-editor {
  border-left: 5px solid #04a895 !important;
}

.yellow-left .ql-editor {
  border-left: 5px solid #ffc646 !important;
}

.gray-left .ql-editor {
  border-left: 5px solid #93a1af !important;
}

.purple-left .ql-editor {
  border-left: 5px solid #7e80e7 !important;
}

.red-left .ql-editor {
  border-left: 5px solid #fe696a !important;
}

.ql-editor {
  background: #f2f2f2;
  min-height: 140px;
  border-top: 1px solid #cccccc !important;
}

.send-button {
  display: inline-block;
  color: #fff !important;
  cursor: default !important;
  font-weight: 500;
  background: #08ada2 !important;
  height: 34px !important;
  min-width: 75px !important;
  padding: 5px 10px !important;
  text-align: center !important;
}

.send-button:hover,
.split-send-button:hover .send-button {
  background: #08b8ad !important;
}

.send-button:disabled,
.split-send-button.disabled,
.split-send-button.disabled:hover,
.split-send-button:hover .send-button:disabled {
  background: #cccccc !important;
}

.send-button:disabled:hover {
  color: #fff !important;
}

.split-send-button {
  display: inline-block;
  color: #ffffff;
  background: #08ada2;
}

.split-send-button:hover {
  background: #08b8ad !important;
}

.split-send-button .send-button {
  min-width: 60px !important;
}

.split-send-button .button-divider {
  color: #cccccc;
  font-size: 1.5em;
  line-height: 1em;
  vertical-align: middle;
}

.split-send-button.disabled .button-divider {
  color: #fff;
}

.cancel-button {
  background-color: #ffffff;
  font-weight: 500;
  color: #828282 !important;
  padding: 5px 12px !important;
  cursor: default !important;
  margin-left: 10px;
  width: auto !important;
  height: 34px !important;
}

.delete-button {
  color: #ffffff;
  font-weight: 500;
  background-color: #fe696a;
  padding: 5px 12px;
  margin-left: 10px;
  width: auto;
  height: 100%;
}

.delete-button:hover {
  background-color: #eb5757;
}

.cancel-button:hover {
  background: #f1f1f1 !important;
}

#save-replies-quill .ql-editor {
  height: 250px;
}

.modal-lg {
  max-width: 55% !important;
}

/* Conversation Table */

.highlight {
  font-weight: 600;
}

.table-container {
  height: 100%;
}

.table-header {
  width: 100%;
  background-color: #dbe0e3;
  border-bottom: 1px solid #f1f1f1;
  padding: 12px 0;
  line-height: 15px;
}

.column-header {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.column-header:last-child {
  padding-right: 15px;
  text-align: right;
}

.conversation-row {
  width: 100%;
  display: inline-block;
  font-size: 13px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  cursor: pointer;
}

.conversation-table-date-header {
  display: flex;
  align-items: center;
}

// .conversation-table__header {
//   background-color: transparent;
// }

.conversation-table-mark-spam,
.conversation-table-mark-active {
  font-size: 14px;
}

div.conversation-table-mark-closed {
  font-size: 14px;
  width: 80px;
}

.customer-portal .conversation-table-bulk-action-menu,
.customer-portal .conversation-table-bulk-action-menu-disabled {
  margin-left: 1.25%;
}

.conversation-table-bulk-action-menu,
.conversation-table-bulk-action-menu-disabled {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-left: 2.5%;
}

.conversation-table-bulk-action-menu .checkbox,
.conversation-table-bulk-action-menu-disabled .checkbox {
  padding-top: 1em;
}

.conversation-table-bulk-action-menu .icon-menu-item,
.conversation-table-bulk-action-menu-disabled .icon-menu-item {
  padding-top: 0.5rem;
  border: none;
}

.conversation-table-bulk-action-menu-disabled {
  color: #999999;
}


.conversation-table-icon-menu-new-activity {
  color: $text-default-color;

  &:hover {
    color: $turquoise;
  }
}

.conversation-table-date-header-content {
  padding-right: 1em;
}

@media (max-width: 1480px) {
  .conversation-table-date-header-content {
    padding-right: 0em;
  }
}

.conversation-row:hover {
  background: #f2f2f2;
}

.conversation-table-row:hover {
  cursor: default;
}

.conversation-table-row-icon {
  text-align: left;
  padding-bottom: 0.25em;
}

.conversation-table-row-icon-task {
  color: #2d9cdb;
  font-size: 0.9em;
}

.conversation-table-row-icon-bell {
  color: #f7831b;
}

.conversation-table-row-partner {
  font-size: 15px;
  padding-left: 2px;
  letter-spacing: -0.09px;
}

.conversation-table-row-age {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}

.conversation-table-row-original-date,
.conversation-table-row-waiting-for {
  font-size: 12px;
  font-weight: 400;
  margin-left: 0.15em;
}

.conversation-table-row-original-date {
  color: #666666;
}

.conversation-table-original-date-header {
  color: #666666;
}

.conversation-table-row-subtext {
  font-size: 13px;
  line-height: 14px;
}

.conversation-table-row-reminder-due,
.due-today {
  color: #f7831b;
  display: inline-block;
  padding-left: 0.5em;
}

.due-later {
  color: #2d9cbd;
  display: inline-block;
  padding-left: 0.5em;
}

.overdue {
  color: #eb5757;
  display: inline-block;
  padding-left: 0.5em;
}

.task-completed {
  color: #04a895;
  display: inline-block;
  padding-left: 0.5em;
}

.conversation-table-row-subject {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70%;
  display: inline-block;
  padding: 0;
  margin-bottom: -6px;
}

.table-row-main-line {
  height: 20px;
  line-height: 18px;
  margin-top: 14px;
}

.table-row-main-line div {
  display: inline-block;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  vertical-align: top;
}

.table-row-main-line div:last-child {
  text-align: right;
  padding-right: 15px;
}

.table-row-main-line div .original-date-line {
  text-align: left;
  overflow: visible;
  text-overflow: initial;
  white-space: pre-wrap;
}

.active-conversation-icon {
  width: 3%;
  font-size: 8px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.table-row-main-line-item-icon-new {
  color: #fb7702;
  font-size: 8px;
}

.table-row-main-line-item-icon {
  color: transparent;
}

.table-row-subtext div {
  font-size: 13px;
  line-height: 14px;
  font-weight: normal;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sort-direction-icon {
  padding-left: 0.6em;
}

.sort-by-button.dropdown-trigger {
  padding: 0;
}

.sort-by-button:hover {
  cursor: pointer;
  color: #666666;
}

/* Conversation Document Table */

/* Conversation detail */

.conversation-contents {
  display: inline-block;
  width: 100%;
}

.conversation-side-panel {
  background-color: #ffffff;
  color: #666666;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 25vw;
  max-height: 100vh;
  max-width: 305px;
  padding: 20px 18px;
  border-left: 5px double #eee;
  font-size: 13px;
  line-height: 24px;
}

.phone-numbers {
  color: #0f3044;
}

.phone-label {
  font-weight: 700;
  margin-right: 4px;
}

.conversation-side-panel h5 {
  margin-bottom: 20px;
  color: #000;
}

.conversation-side-panel a {
  font-weight: 500;
  color: #03a08f;
}

.conversation-side-panel a:hover {
  text-decoration: none;
  color: #08ada2;
}

.conversation-status {
  font-size: inherit;
}

.forward-response-item {
  margin-left: 40px;
}

.draft-item {
  border-left: 5px solid #3197d6;
}

.note-item {
  border-left: 5px solid #ffc646;
}

.system-message-item {
  background-color: #f5f5f5;
}


.approval-item {
  border-left: 5px solid #04a895;
}

.rejection-item {
  border-left: 5px solid #fe696a;
}

.approval-request-item {
  border-left: 5px solid #f7831b;
}

.invoice-thread-item {
  border: 1px solid #ccc;
  border-left: double #695dfa;
  padding: 14px 28px;
  margin: 0 20px;
  margin-bottom: 5px;
  width: 400px;
  color: #333;
}

.invoice-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.invoice-detail-container {
  text-align: center;
  width: 120px;
  border-right: 1px solid #999;
}

.invoice-detail-container:last-child {
  border: 0px;
}

.invoice-detail-label {
  color: #666;
}

.conversation-thread-item:first-child {
  border-top: 0px;
}

.thread-item-age {
  font-size: 0.866rem;
  color: $gray3;
}

.response-color {
  color: #93a1af;
}

.note-color {
  color: #ffc646;
}

.forward-color {
  color: #7e80e7;
}

.draft-color {
  color: #3197d6;
}

.approval-request-color {
  color: #f7831b;
}

.approval-color {
  color: #04a895;
}

.rejection-color {
  color: #fe696a;
}

/* Template Editor */

.reply-form-label {
  width: 80px;
}

#save-replies-quill .ql-container {
  position: static;
}

.template-modal {
  max-width: 740px !important;
}

.set-to-default-label {
  width: 200px;
  align-self: center;
}

.template-variable-dropdown {
  position: absolute;
  top: 10px;
  right: 6px;
}

/* Conversation Editor */

.conversation-arrows {
  display: flex;
  align-items: center;
  color: #999999;
}

.conversation-editor {
  padding-bottom: 2rem;
  padding-right: 1rem;

  &__mode-buttons {
    button {
      margin-right: 1.466rem;
    }
  }
}

.conversation-editor-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  margin: 0 20px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}

.conversation-editor-header h4 {
  margin: 0;
}

.conversation-editor-header .badge {
  font-weight: 400;
}

.html-toggle-badge-col {
  align-items: center;
}

.icon-menu-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  margin-left: 0;
}

.icon-menu {
  display: flex;
  padding: 0 1em;
  height: 54px;
  color: #999999;
}

.icon-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  margin-top: 0.5em;
  margin-right: 1em;
  cursor: pointer;
  padding: 0;

  .dropdown & {
    border: none;
  }
}

.icon-menu-item.task-menu-first-item {
  margin-left: 10px;
}

.user-picker-compact-icon {
  font-size: 14px;
}

.conversation-table-icon-menu-item {
  border-right: none;
}

.icon-menu-item:disabled {
  cursor: auto;
}

.icon-menu .icon-dropdown {
  font-size: 0.75em;
  color: #4f4f4f;
}

.icon-menu-item .fal,
.icon-menu-item .far {
  font-size: 14px;
  color: #444444;
}

.icon-menu-item .far {
  font-weight: 300;
}

.icon-menu-item:disabled .fal,
.icon-menu-item:hover:disabled .fal,
.icon-menu-item:disabled .fas {
  color: #999;
}

.icon-menu-item.active .fal,
.icon-menu-item:hover .fal,
.icon-menu-item.active .far,
.icon-menu-item:hover .far {
  color: #2d9cdb;
}

.icon-menu-item-label {
  font-size: 11px;
  padding-top: 4px;
}

.html-toggle {
  display: flex;
  align-items: flex-end;
  padding-bottom: 6px;
  font-size: 13px;
  color: #4f4f4f;
}

.html-toggle span {
  padding: 0 4px;
  cursor: default;
}

.html-toggle span.link {
  cursor: pointer;
  color: #2d9cdb;
}

.email-header-label {
  display: inline-block;
  margin-right: 5px;
}

.email-input {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 13px;
  border: solid 1px #cccccc;
  background-color: #f2f2f2;
  color: #0f3044;
}

.email-input:focus {
  border-color: #2d9cdb;
}

.email-address {
  display: inline-block;
  margin-right: 5px;
  border-radius: 12px;
  padding: 5px 8px;
  font-size: 13px;
  background-color: #eeeeee;
  color: #0f3044;
  margin-bottom: 4px;
}

.plus-email {
  font-size: 20px;
  vertical-align: middle;
  color: #cccccc;
}

.subject-input {
  width: 100%;
  font-size: 13px;
  padding: 4.5px;
  border-color: #cccccc;
}

.subject-input:focus {
  border-color: #2d9cdb;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  color: #0f3044;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 0.4em 0;
  max-width: 23em;
  margin-bottom: 1em;
}

.attachment-icon {
  font-size: 2.4em;
  margin-right: 3%;
  padding-left: 3%;
  width: 12%;
}

.attachment-size {
  color: #999999;
}

.attachment-content-container {
  position: relative;
  margin-right: 2%;
  width: 72.5%;
  height: 2.8em;
}

.attachment-content {
  position: absolute;
  width: 100%;
}

.attachment-content div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attachment-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
}

.attachment-overlay:hover {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #03a08f;
  background-color: rgba(250, 250, 250, 0.8);
  cursor: pointer;
}

.attachment-right {
  font-size: 1.4em;
  padding-left: 3%;
  padding-right: 3%;
  border-left: 1px solid #dddddd;
  width: 11.5%;
  color: #999999;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  display: flex;
  justify-content: center;
}

.attachment-download:hover {
  color: #03a08f;
}

.attachment-delete:hover {
  color: #eb5757;
}

.attachment-size {
  margin-top: 0em;
}

.attachment-pdf {
  color: #eb5757;
}

.attachment-text {
  color: #999999;
}

.attachment-image {
  color: #0e92e3;
}

.attachment-excel {
  color: #219653;
}

.attachment-word {
  color: #166694;
}

.responsive-iframe {
  padding: 0;
  border: none;
  overflow: hidden;
}

.attachment a {
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden-input {
  padding: 0;
  margin: 0;
  border: 0;
  height: 0;
  position: absolute;
}

.quill-container {
  position: relative;
  width: 660px;
  max-width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 7px;
}

.related-conversation {
  padding: 0.4em 0.8em;
  border: 1px solid #cccccc;
  margin-bottom: 0.2em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.related-subject {
  display: inline-block;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-ago {
  max-width: 40%;
  padding-left: 1em;
  font-size: 0.85em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-editor-arrow,
.conversation-editor-transparent {
  padding: 0 1em;
}

.conversation-editor-arrow :hover {
  color: #2d9cdb;
  cursor: pointer;
}

.conversation-editor-transparent {
  color: transparent;
}

/* Manage */

.manage-signatures__helper-text {
  margin-top: 0.6rem;
  margin-bottom: 1.6rem;
  margin-left: 0.4rem;
}

.connector-badge {
  border-radius: 6px;
  padding: 0.3em 0.6em;
}

.badge-syncing {
  color: #2d9cdb;
  background-color: #d5ebf8;
}

.badge-error {
  color: #ffffff;
  background-color: #eb5757;
}

.email-settings-col {
  border-right: solid 1px #cccccc;
  padding-bottom: 1em;
}

.email-settings-col:last-child {
  border-right: none;
}

.incoming-config-status {
  min-height: 2em;
  text-align: right;
}

.manage-close {
  color: #08b8ad;
}

.manage-close:hover {
  cursor: pointer;
}

.icon-picker {
  display: inline-block;
}

.icon-picker-container {
  display: inline-flex;
  align-items: center;
  height: 34.5px;
  border: 1px solid #cccccc;
}

.icon-picker-container.disabled {
  background-color: #f2f2f2;
}

.icon-picker-label {
  font-weight: 700;
  margin-bottom: 6px;
}

.default-icon {
  font-size: 1.1em;
  padding: 0 2px;
  cursor: default;
  font-family: Times;
  vertical-align: middle;
}

.perspective-icon {
  color: #666666;
  padding: 0.5em 0;
  padding-left: 1.2em;
}

.perspective-icon:last-child {
  padding-right: 1.2em;
}

.perspective-icon .default-icon:hover,
.perspective-icon.active-icon .default-icon {
  border-color: #08b8ad;
}

.icon-picker-container.disabled .perspective-icon:hover {
  color: #666666;
}

.perspective-icon:hover,
.perspective-icon .active-icon,
.icon-picker-container.disabled .perspective-icon .active-icon {
  color: #08b8ad;
}

.custom-radio {
  padding-bottom: 0.5em;
}

.first-radio {
  width: 12em;
}

.email-address-box {
  padding: 0.5em 1em;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-address-box .edit-email-input {
  padding: 0px;
  outline: none;
  border: none;
  width: 20em;
}

.email-address-box .readonly-email {
  font-size: 0.9em;
  font-family: "Courier New", Courier, monospace;
}

.email-address-box .readonly-email:empty:before {
  content: "\200b";
}

.manage-content {
  height: calc(100% - 97.5px);
  padding: 16px 20px;
}

.select-accounting {
  font-weight: 600;
  padding-bottom: 1em;
  line-height: 18px;
}

.select-accounting-subtext {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 18px;
}

.connector-container {
  max-width: 100%;
}

.connector-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 10px;
  border: 3px solid #fff;
}

.connector-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 800px;
}

.connector-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 6em;
}

.connector-name {
  font-weight: 600;
  font-size: 1.1em;
}

.connector-description {
  font-weight: 600;
  color: #999999;
}

.connector-button,
.connector-connect-button {
  cursor: pointer;
}

.connector-connect-button {
  max-height: 3em;
}


.registered-connector-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.selected-connector,
.connector-icon:hover {
  border: 3px solid #097abf;
}

.connector-icon img {
  height: 60px;
}

.registered-connector-icon img {
  height: 100px;
}

.connector-status-registration {
  color: #eb5757;
}

.connector-status-syncing {
  color: #097abf;
}

.connector-status-defaults {
  color: #097abf;
}

.connector-status-complete {
  color: #219653;
}

.registered-connector-header {
  font-weight: 600;
  font-size: 1.1em;
}

/* .connector-registered {
  margin-right: 30px;
  margin-top: -28px;
  margin-bottom: 28px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  color: #666;
} */

.connector-request {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.connector-request-input {
  margin-top: 19px;
  font-size: 11px;
  width: 160px;
  padding: 6px;
}

.connector-request-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
}

.connector-request-input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
}

.connector-request-input:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
}

.connector-request-input:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
}

.connector-buttons button {
  margin: 20px 0;
  padding: 5px 20px !important;
}

.connector-buttons button:first-child {
  margin-right: 10px;
}

.connector-modal-title {
  border: 0;
  margin: 10px auto;
  margin-bottom: 0px;
  font-weight: 700;
}

.connector-modal-body {
  font-size: 14px;
  text-align: center;
}

.connector-modal-footer {
  border: 0px;
  margin-bottom: 20px;
}

.success-logo-icon {
  height: 60px;
  width: 60px;
}

/* Company Settings */
.saved-message {
  margin-left: 12px;
  font-size: 18px;
  color: #999;
}

.context-group-settings {
  margin: 1.5em 1.5em 0 1.5em;
}

.email-mapping-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2.5em;
}

.company-identifiers-button {
  margin: 0.6em 0;
  height: 2.8em;
  display: flex;
  align-items: center;
}

/* Company Profile */

.my-company-profile-address-second {
  display: flex;
  flex-direction: row;
}

.company-profile-email-company {
  padding: 1.5em;
}

.company-profile-email-company-header {
  font-weight: 500;
  font-style: italic;
  color: #666666;
}

.company-profile-email-company-actions-label {
  color: #666666;
  font-weight: 400;
  padding-top: 2.5em;
  padding-bottom: 0.7em;
}

.company-profile-email-company-actions-buttons {
  display: flex;
  flex-direction: column;
}

.company-profile-email-company-action-button {
  width: 20em;
}

.company-profile-email-company-helper-text {
  margin-top: 2.5em;
  color: #666666;
}

.company-profile-disconnected {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.not-connected-image {
  max-width: 15%;
  margin-top: 15%;
}

.not-connected-text {
  margin-top: 1em;
  font-size: 1.5em;
  text-align: center;
  color: #0f3044;
  padding: 0 2em;
}

.not-connected-button {
  margin-top: 1em;
}

.company-profile-content {
  max-width: 95%;
  margin-top: 0em;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.company-profile-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5em;
}

.company-profile-column {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 1em;
}

.full-company-profile {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 2em;
}

.login-container .full-company-profile .input-container {
  margin: 0.2em 0.7em;
}

.login-form-error {
  width: 520px;
  margin: 0 auto;
  background-color: #fff;
  padding: 26px;
  text-align: center;
}

.login-form-container {
  width: 520px;
  min-height: 380px;
  max-height: 460px;
  margin: 0 auto;
  background-color: #fff;
  padding: 26px;
}

.login-form-container .error-text {
  margin-top: -10px !important;
  margin-bottom: 6px !important;
  width: 80%;
  display: block;
  margin: 0 auto;
  color: #eb5757;
}

.login-form-container .checkbox {
  margin-left: 7px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.full-company-profile .input-container {
  margin: 0.5em 0;
}

.actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h4.company-profile-contact-details {
  margin: 0 0;
  padding-top: 0.9em;
  color: #0f3044;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 0.2em 0.4em;
  width: 100%;
  margin: 0.05em 0;
  overflow-wrap: break-word;
}

.spfdkim-content .input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 0;
  width: 100%;
  margin: 0;
  overflow-wrap: break-word;
}

.spfdkim-content .input-container div {
  border: none;
  padding: 1em 0 1.5em 0;
}

.spfdkim-content p {
  margin: 2em 0 1.5em 0;
}

.input-container input:-webkit-autofill {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 100px #ffffff inset !important;
}

.input-container input,
.input-container div {
  font-weight: 400;
  color: #0f3044;
  border: 1px solid #999999;
  padding: 0.4em 0.4em;
  background: inherit;
}

.input-container input:disabled,
.input-container div:disabled {
  border-color: #ccc;
}

.input-container input {
  width: inherit;
}

.input-container label {
  margin: 0;
  color: #0f3044;
  font-size: 1em;
  font-weight: 500;
  padding-top: 0.5em;
  padding-bottom: 0.1em;
}

.input-container a {
  color: #08ada2;
  font-weight: 500;
  text-decoration: none;
}

.input-container a:hover {
  color: #00c9b2;
}

.input-container span {
  font-size: 0.8em;
  margin-right: 6px;
}

.input-container hr {
  margin: 0.2em 0;
  color: #cccccc;
}

.button-clear {
  background-color: transparent;
  color: #828282 !important;
}

.button-gmail,
.button-gmail-disconnect {
  font-weight: 500;
  background-color: #c33c2d;
  color: #ffffff;
  min-width: 4.5em;
  height: 2.8em;
}

.button-gmail:hover {
  background-color: #d44638;
  cursor: pointer;
}

.button-gmail-disconnect {
  background-color: #ffffff;
  color: #c33c2d;
  border: solid 2px #c33c2d;
}

.button-gmail-disconnect:hover {
  background-color: #c33c2d;
  color: #ffffff;
  border: solid 2px #c33c2d;
}

.button-green {
  font-weight: 500;
  background-color: #08ada2;
  color: #ffffff;
  min-width: 4.5em;
  height: 2.8em;
}

.button-green:hover {
  background-color: #08b8ad;
}

.button-green-invert {
  font-weight: 500;
  color: #08ada2;
  min-width: 4.5em;
  min-height: 2.8em;
  padding: 0.25em 0.7em;
}

.button-green-invert:hover {
  color: #08b8ad;
}

.button-select-input {
  padding: 0.2em;
}

.button-select-input:focus {
  border: 1px solid #2d9cdb;
}

.button-select-input .fas-angle-down {
  color: #999999;
}

.select-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .button-select-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #999999;
    padding: 5px 0.4em;
    width: 100%;
    min-height: 2.4rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  .dropdown,
  .lockstep-dropdown {
    width: 100%;
  }
}

.select-input-arrow-down {
  padding-right: 0.35em;
  margin-left: 1rem;
}

.button-green:disabled {
  background: #cccccc;
  opacity: 1;
}

h4.company-profile-company-name {
  padding: 1em 0 1.26em 0.2em;
  font-weight: bold;
}

.button-cancel {
  cursor: pointer;
  font-weight: 500;
  background-color: #ffffff;
  color: #999999;
  width: 4.6em;
  height: 2.8em;
}

.modal-button-cancel {
  padding: 7px 15px;
  margin: 0 2px;
  border: 1px solid #ccc;
}

.modal-button-reset {
  padding: 7px 15px;
  background-color: #dbe0e3;
  border: 1px solid #ccc;
}

.button-cancel:hover {
  color: #666666;
}

.button-edit.editor-button {
  cursor: default !important;
  width: auto !important;
  padding-right: 12px !important;
}

.button-edit.editor-button:hover {
  color: #08ada2 !important;
}

.button-edit {
  font-weight: 500;
  background-color: #ffffff;
  color: #03a08f;
  width: 4.5em;
  height: 2.8em;
}

.button-edit:hover {
  color: #08ada2;
}

.button-edit-bordered {
  border: 1px solid #03a08f;
}

.button-edit-bordered:hover {
  border-color: #08ada2;
}

.col-small-padding-right {
  padding-right: 0;
}

.col-small-padding-left {
  padding-left: 0;
}

.company-profile-company-name {
  margin: 0em 0 0.4em 0em;
}

.vendorLink {
  margin-bottom: 1em;
}

.input-container input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.input-container input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.input-container input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.input-container input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.fa-circle.new-company-icon {
  color: #fb7702;
  font-size: 8px;
  padding-right: 8px;
}

.profile-attribute {
  display: flex;
  flex-direction: row;
  padding: 0.65em 1em 0.2em 0.1em;
  border-bottom: solid 1px #dddddd;
}

.profile-attribute-label {
  flex-grow: 1;
  flex-basis: 0;
  font-weight: 600;
  color: #666666;
}

.profile-attribute-content {
  flex-grow: 1;
  flex-basis: 0;
  color: #666666;
}

/* Attachment Management */

.dropzone-border {
  border: 4px dotted #666;
}

.dropzone-border-clear {
  border: 4px solid transparent;
}

.file-upload-item.shared-document-selector {
  padding-left: 1em;
  margin-left: -6px;
  line-height: 1em;
}

.attach-file-content {
  min-height: 160px;
}

.file-upload-item {
  display: flex;
  align-items: center;
  padding: 0.8em 1.2em;
  border-bottom: 1px solid #cccccc;
}

.file-upload-item:last-child {
  border-bottom: none;
}

.file-upload-item .filetype-icon {
  font-size: 2em;
  line-height: 1em;
  padding-right: 8px;
}

.file-upload-item .attachment-name {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-upload-item .attachment-close {
  flex-grow: 1;
  text-align: right;
  font-size: 1.2em;
  color: #999999;
  font-weight: 500;
}

.file-upload-item .attachment-close:hover {
  color: #666666;
}

.file-upload-item label>div {
  display: flex;
  margin-top: -3px;
  margin-left: 7px;
}

.no-file-selected {
  color: #999999;
  font-size: 1.6em;
  margin-top: 2em;
  text-align: center;
}

.add-to-shared-checkbox {
  margin-top: 1em;
  margin-left: -5px;
}

.shared-documents-row {
  line-height: 1;
}

/* Report section */
.reports {
  height: calc(100vh - 96px);
  min-height: 750px;
}

.nav-filter-reports {
  padding: 1em;
  padding-bottom: 0;
}

.filter-time-reporting {
  color: #666666;
  font-size: 1em;
  padding-top: 0.1em;
  padding-right: 2em;
}

.nav-filter-reports h4 {
  float: left;
}

.nav-filter-reports ul {
  list-style: none;
  text-align: left;
}

.reports-filter-item {
  display: inline-block;
  padding: 0em 0.6em;
  font-size: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-bottom: 2px;
}

.reports-filter-item:hover {
  color: #097abf;
  border-bottom: 2px solid #097abf;
  cursor: pointer;
}

.reports-filter-item.active {
  color: #097abf;
  border-bottom: 2px solid #097abf;
  font-weight: 600;
}

.reports-content-blocks {
  column-gap: 0.4em;
  background-color: #eeeeee;
}

.reports-content-blocks .row {
  margin: 1em 0;
  padding: 1em;
}

.reports-content-block {
  padding: 1em 1em 0.7em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reports-content-block-column {
  padding: 0 5px 0 5px;
}

.reports-content-block h4 {
  font-weight: lighter;
  font-size: 1.2em;
  margin-bottom: 0.4em;
  flex-grow: 1;
}

.reports-content-block h3 {
  font-weight: normal;
  font-size: 1.5em;
}

pre#reports-pre {
  margin-bottom: 0;
}

.table-tasks-report {
  margin: 1em;
  border: 1px solid #666666;
}

.reports-chart-area {
  height: 30vh;
  margin: 1em;
  padding: 1em 2em 2.5em 0;
  border: #666666 solid 1px;
}

.reports-charts-title {
  padding: 0 0 0.7em 1.5em;
  font-weight: 500;
  font-size: 1.15em;
}

path.recharts-rectangle.recharts-tooltip-cursor {
  opacity: 0.6;
}

#tasks-responses-label-text {
  color: #ffffff;
}

span.reports-charts-title {
  padding: 1em 1em 0em 2em;
  width: 100%;
}

span.reports-charts-title:hover {
  cursor: pointer;
}

span.reports-charts-title-selector {
  padding: 1em 1em 1em 2.2em;
}

span.reports-charts-title-selector bottom {
  padding: 1em 1em 1.1em 1em;
}

span.reports-charts-title-selector:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #2b8cc8;
  cursor: pointer;
}

#approvers-dropdown-menu {
  margin-top: 0.2em;
  width: 24.5em;
  max-width: 50vw;
}

.reports-export-button {
  margin-right: 1em;
  color: #46483f;
  font-size: 1.2em;
  padding: 0;
  padding-right: 5px;
}

.reports-export-button:hover {
  cursor: not-allowed;
}

.reports-noData {
  font-size: 2em;
  color: rgba(0, 0, 0, 0.3);
}

/* Invoice Sidebar */

.invoice-sidebar-content {
  color: #0f3044;
  line-height: 1.2em;
  padding-bottom: 0.3em;
}

.invoice-sidebar-header {
  padding-bottom: 0.3em;
  color: #666666;
}

.invoice-sidebar-icon {
  padding-right: 1em;
  color: #666666;
}

.invoice-sidebar-icon-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 0.6em;
}

.invoice-sidebar-number {
  font-size: 1.1em;
  padding-top: 0.1em;
  padding-bottom: 0.25em;
}

.invoice-sidebar-key {
  font-weight: 500;
  line-height: 1.5;
}

.invoice-sidebar-key-col {
  padding: 0 5px 0 5px;
}

.invoice-sidebar-value {
  line-height: 1.5;
}

.invoice-sidebar-value-col {
  padding: 0 5px 0 5px;
}

/* Main Content Header */

#content-header-container .search {
  display: inline-block;
}

#content-header-container .search input,
#content-header-container .search span {
  background: #f2f2f2;
  color: #000000;
}

.new-company-icon-actions {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  margin-right: 1em;
  border: none !important;
}

.new-company-icon-actions:hover,
.new-company-icon-actions:active,
.new-company-icon-actions:focus {
  color: #04a895;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all 0.5s ease-in-out;
}

.new-company-header-icon {
  color: #fb7702;
  font-size: 8px;
}

span.fa-circle.icon-hidden {
  color: transparent;
  padding-right: 3px;
}

/* Generic Components */

.accordion-section {
  padding: 0.4em 0em;
  margin: 4em 0;
}

.accordion-section-content {
  margin-top: 0.4em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.accordion-section-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
  padding-bottom: 0.4em;
}

.accordion-section-label h5 {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
}

.accordion-icon {
  font-size: 1.8em;
  color: #999999;
}

.accordion-section-label-side-nav {
  display: flex;
  justify-content: space-between;
}

.accordion-side-nav {
  padding: 0.6em 32px;
}

.accordion-side-nav-menu-icon {
  padding-right: 14px;
  max-width: 26px;
}

.accordion-side-nav-menu-icon.bell {
  padding-left: 2px;
}

.reminders-item {
  padding-left: 0;
}

/* Documents view */

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.documents-document {
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: 500;
  min-width: 0;
  color: $link-color;

  .documents-document-name {
    max-width: 90%;

    &:hover {
      color: $link-hover-color;
    }
  }
}

.documents-file-name-icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documents-file-name span {
  font-size: 1.4em;
  padding-right: 0.3em;
}

.documents-file-name div {
  font-size: 1em;
  line-height: 1;
}

.documents-file-download {
  color: #999999;
}

.documents-file-download:hover {
  color: #03a08f;
}

.documents-view-row:hover {
  cursor: pointer;
}

.documents-subject-subtext {
  font-size: 0.8em;
}

.documents-direction {
  font-size: 1em;
}

.documents-date {
  font-size: 0.8em;
}

/* Statements view */

.send-statements-button {
  cursor: pointer;
  color: #03a08f;
  cursor: pointer;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-right: 3rem;
  margin-top: 0.25rem;
}

.statements-menu-bar {
  padding: 0.5em 1em;
  background-color: #f2f2f2;
  margin-top: 1px;
  border-top: 1px solid #ddd;
}

.statements-view-row {
  cursor: default;
}


.statement-included-message {
  margin-left: 20px;
  margin-top: -16px;
  font-style: italic;
  color: #666;
}

/* Workflows */

.workflow-create-button {
  min-width: 10em;
}

.workflow-new-bottom-buttons {
  padding-top: 1.5em;
  display: flex;
  justify-content: flex-end;
}

.workflows-edit {
  color: #08ada2;
}

.workflows-edit:hover,
.workflows-edit:focus {
  color: #08b8ad;
  cursor: pointer;
}

.workflows-delete {
  color: #eb5757;
}

.workflows-delete:hover,
.workflows-delete:focus {
  color: #fe696a;
  cursor: pointer;
}

.table-header-actions {
  padding-left: 1.8em;
}

.table-data-manage {
  border-bottom: none;
}

.table-data-manage:hover {
  background-color: transparent;
}

input.form-check-input:checked {
  background-color: red;
}

.workflow-form-type {
  padding-top: 1em;
  padding-bottom: 0.3em;
}

.workflow-form-header {
  padding-top: 0.2em;
  padding-bottom: 0.3em;
}

.reminders-radio-input {
  width: 100%;
  columns: 2;
}

.set-reminder-modal-content {
  margin-top: 1.66rem;
}

.set-reminder-modal__save {
  margin-top: 2.66rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.set-reminder-preview-time {
  color: #999999;
}

li.react-datepicker__time-list-item--selected {
  background-color: #03a08f !important;
}

.reminder-thread-item-icon {
  color: #f7831b;
  font-size: 1.2em;
}

.reminder-item-cancel {
  text-decoration: underline;
  padding-left: 0.6em;
  font-size: 1.05em;
  cursor: pointer;
}

.reminder-item {
  background-color: #fff2d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.synthetic-email-title {
  font-weight: bold;
}

.email-item {
  margin: 0 1.5em;
}

.channel-select-text {
  color: #666666;
}

.channel-selector:hover,
.channel-selector:focus,
.channel-selector:active {
  color: #03a08f;
}

.auto-complete-input-icon,
.auto-complete-dropdown-icon {
  color: #cccccc;
}

.auto-complete-dropdown-icon.solid {
  color: initial;
}

/* Cancel request response modal */

.cancel-request-response-bottom-buttons {
  display: flex;
  justify-content: right;
  align-items: center;
}

.cancel-request-response-bottom-buttons-no-email {
  max-width: 15em;
}

/* CRUD List - CRUDList.jsx */

.crud-list-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  padding: 0 1em 0.7em 1em;
}

.crud-list-headings {
  max-width: 80%;
}

.manage-card-title {
  font-size: 1.4em;
}

.crud-list-description {
  font-size: 1em;
}

.crud-list-add-new {
  color: #08ada2;
  font-size: 1.1em;
}

.crud-list-add-new:hover {
  color: #08b8ad;
  cursor: pointer;
}

.crud-list-actions-update {
  color: #08ada2;
}

.crud-list-actions-update:hover {
  color: #08b8ad;
  cursor: pointer;
}

.crud-list-actions-delete {
  color: #eb5757;
  margin-left: 1em;
}

.crud-list-actions-delete:hover {
  color: #fe696a;
  cursor: pointer;
}

.crud-list-actions-update,
.crud-list-actions-delete,
.crud-list-actions-other {
  margin-left: 1em;
}

.crud-list-actions-update:first-child,
.crud-list-actions-delete:first-child,
.crud-list-actions-other:first-child {
  margin-left: 0em;
}

.crud-list-actions-other {
  color: #097abf;
}

.crud-list-actions-other:hover {
  color: #097abf;
  cursor: pointer;
}

.crud-list-row {
  height: 4em;
}

.crud-list-actions-header {
  padding-left: 9px;
}

.email-click {
  color: $turquoise;
  padding: 0;
}

.email-click:hover {
  opacity: .5;
}

.dropdown-max-height-scroll {
  max-height: 640px;
  overflow-y: scroll;
}

.dropdown-button-border {
  border: 1px solid #999999;
}

.h36 {
  height: 36px;
}

.table-padding {
  padding-left: 16px;
  padding-right: 20px;
}

.table-padding-left {
  padding-left: 16px;
}

.dd-table-row {
  padding-top: 10px;
}

.font-weight-normal {
  font-weight: normal;
  font-size: 13px;
}

.font-weight-medium {
  font-weight: 500;
}

// Overrides various default styles from the Inbox CSS and Font Awesome on the account drop-down search icon in self service. Note that eventually
// we should replace this with the SVG search icon we are using elsewhere, per Kelley. When we do this rule probably won't be needed.
#content-header-container .search {
  &.account-search-container {
    input {
      background-color: #ffffff;
      border: 1px solid #6F828E;
    }

    input:focus {
      border: 1px solid $turquoise;
    }

    span {
      background-color: #ffffff;
      color: #6f828e;
      font-weight: normal;
      height: revert;
      line-height: revert;
      padding: 0 8px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

.text-align-right {
  text-align: right;
}

.vertical-header-padding-8 {
  padding: 8px 0;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px;
}

.multi-customer-dropdown {
  overflow-x: hidden;
}

.padding-right {
  padding-right: 16px;
}

html #content-header-title div div.content-header .dropdown div span.lockstep-dropdown__menu.show.multi-customer-dropdown.dropdown-max-height-scroll.breakout {
  width: 100% !important;
}

.dropdown-menu.show.filterDropdown-menu {
  width: 33rem;
  height: 25.5rem;
}