@import "../base";

.tooltip-container .view-selector-nav-item.small {
  width: 100%;
}

.view-selector {
  width: 21rem;
  border-right: 1px solid $gray2;
  background-color: $dark-navy;
  color: $text-inverse-color;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 5rem);

  > .view-selector-company-name {
    font-size: 1.4rem;
    line-height: 1.68rem;
    font-weight: 600;
    padding: 1.08rem;
    padding-bottom: 0.58rem;
  }

  > .view-selector-perspective-name {
    padding: 1.08rem;
    padding-top: 0rem;
    font-size: 0.92rem;
    font-weight: 600;
    color: rgba($text-inverse-color, 0.6);
  }

  .view-selector-nav-item {
    color: $text-inverse-color;
    display: flex;
    padding: 1.08rem 0.6rem;
    align-items: center;

    .expandable-nav-link.active,
    &.active,
    &:hover {
      background-color: $blue-black;
    }

    &__icon {
      width: 32px;
      margin-right: 0.66rem;
      display: flex;
      justify-content: center;
    }

    &__content {
      max-width: 60%;
    }

    &__content-no-truncate {
      max-width: 100%;
    }

    &__badge {
      margin-left: auto;
      margin-right: 1.06rem;
      display: inline-flex;
      padding: 0.2rem 0.3rem;
      font-size: 0.93rem;
      line-height: 0.87rem;
      min-width: 2.12rem;
      align-items: center;
      justify-content: center;

      &__spacer {
        width: 0.94rem;
      }

      &--alert {
        background-color: $pink;
      }

      &--info {
        background-color: transparent;
      }
    }

    &.small {
      padding: 0.66rem 0.6rem;
      font-size: 1rem;
    }
  }
}
