@import "../base";

.conversation-action-buttons {
  display: flex;
  flex-wrap: wrap;
  --gap: 1.6rem;
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  .button-primary {
    white-space: nowrap;
  }

  .conversation-action-buttons__move__menu {
    padding: 0.66rem 0.76rem;
  }
}
