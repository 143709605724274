@import "../base";

.card .filter-builder {
  margin: 1rem 0.66rem;
  min-width: 53.66rem;

  &__add-new {
    margin-bottom: 2rem;
    margin-top: 0.866rem;
    font-size: 0.93rem;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    > button:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }
}
