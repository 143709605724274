@import "../base";

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
}

.background-masker {
  position: absolute;
  background: #ffffff;
}

.dropdown-menu-masker-top,
.dropdown-menu-masker-bottom {
  height: 0.6rem;
  right: 0;
  left: 0;
}

.dropdown-menu-masker-bottom {
  bottom: 0;
}

.dropdown-menu-masker-left1 {
  left: 0;
  width: 0.53rem;
  top: 0.6rem;
  height: 1.2rem;
}

.dropdown-menu-masker-right1 {
  width: 0.53rem;
  right: 0px;
  top: 0.6rem;
  height: 1.2rem;
}

.dropdown-menu-masker-spacer1 {
  left: 0px;
  right: 0px;
  top: 1.8rem;
  height: 0.4rem;
}

.dropdown-menu-masker-left2 {
  left: 0;
  width: 0.53rem;
  top: 2.2rem;
  height: 1.2rem;
}

.dropdown-menu-masker-right2 {
  width: 0.53rem;
  right: 0px;
  top: 2.2rem;
  height: 1.2rem;
}

.dropdown-menu-masker-left3 {
  left: 0;
  width: 0.53rem;
  top: 3.8rem;
  height: 1.2rem;
}

.dropdown-menu-masker-right3 {
  width: 0.53rem;
  right: 0px;
  top: 3.8rem;
  height: 1.2rem;
}

.dropdown-menu-masker-spacer2 {
  left: 0px;
  right: 0px;
  top: calc(27px + 1.6rem);
  height: 0.4rem;
}

.loading-dropdown-menu {
  height: 5.7rem;
}

.loading-dropdown-item {
  width: 15rem;
  height: 1.2rem;
  margin: 0.3rem 0.8rem;
  background: $gray5;
}

.auto-complete-input {
  border: 1px solid $gray3;
  display: flex;

  input[type="text"] {
    background: none;
    flex-grow: 1;
  }

  &.active {
    border-color: $turquoise;
  }
}

.auto-complete-input__table-data-search {
  border: 1px $gray3 solid;
  border-radius: 2px;
  padding: 0.467rem 0.8rem;

  .icon {
    color: $gray2;
  }
}

.active-search-icon {
  color: $turquoise;
}
