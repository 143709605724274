@import "../base";

.perspective-selector {
  border-right: 1px solid $gray2;
  display: flex;
  flex-direction: column;
  background-color: $dark-navy;

  &__profile-button {
    padding: 0.733rem 0.66rem;
    margin-top: auto;

    .dropdown-avatar {
      padding: 0;
    }
  }
}

.perspective-container {
  padding: 0.733rem 0.66rem;
  cursor: pointer;
  position: relative;

  &.active,
  &:hover {
    .avatar__empty--perspective {
      background-color: $white-background-color;
    }
    .avatar__empty__text text {
      fill: $gray2;
    }
  }

  .perspective-container__active-indicator {
    color: $pink;
    display: none;
    position: absolute;
    top: 0.733rem;
    right: 0.66rem;

    &.active {
      display: unset;
    }
  }
}

.perspective-item {
  height: 2.133rem;
  width: 2.133rem;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-workspace-dropdown {
  padding: 0;

  &__title {
    font-size: 0.7rem;
    color: $gray2;
  }

  &__icon-container {
    color: $white-background-color;
  }
}
