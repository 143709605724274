@import "../base";

.dropdown::after,
.dropdown::before {
  display: none !important;
}

.customer-portal,
.flyout {
  //TODO: dropdown styling needs to be standardized and needs some love
  // [data-toggle="dropdown"],
  // [data-toggle="dropdown-filter"],
  // .dropdown-trigger {
  //   padding: 0.467rem 0.8rem;
  // }

  // [data-toggle="dropdown"],
  // [data-toggle="dropdown-filter"] {
  //   border: 1px solid $gray2;
  //   padding: 0.467rem 0.8rem;
  // }

  .dropdown-avatar {
    border: none;
  }
}

.dropdown {
  .customer-portal [data-toggle="dropdown"],
  .customer-portal .dropdown-trigger {
    margin-top: 0;
    font-size: 0.93rem;
  }
}

.table-heading-row {
  [data-toggle="dropdown"],
  .dropdown-trigger {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
}

.dropdown-trigger {
  border-radius: 2px;
  font-size: 0.93rem;
  font-weight: 500;
}

.dropdown {
  display: inline-block;

  [data-toggle="dropdown"],
  [data-toggle="dropdown-filter"],
  .dropdown-trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.button-action-icon {
      border: none;
      border-radius: 100%;

      &::after {
        display: none;
      }
    }

    &[aria-expanded="true"] {
      border-color: $turquoise;
    }
    &.active-dropdown-trigger {
      border-color: $turquoise;
    }
  }
}

.dropdown-menu {
  border-radius: 2px;
  border-color: $turquoise;

  &:hover {
    cursor: default;
  }

  &.show {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 0.3rem;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.dropdown-item.disabled,
.dropdown-item-clean.disabled {
  border-radius: 0.25em;
  background: inherit;
  color: #bbbbbb;
  cursor: not-allowed;
}

.dropdown-header ~ .dropdown-item {
  padding-left: 1.5em;
}

.dropdown-icon {
  color: #0f3044;

  &:hover {
    color: #08b8ad;
  }
}

.dropdown-divider:hover {
  cursor: default;
}

.dropdown-item,
.dropdown-item-clean {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0;
  color: $dark-navy;
  font-weight: 500;
  display: inline-block;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item.active,
.dropdown-item:focus,
.dropdown-item-clean:hover,
.dropdown-item-clean:active,
.dropdown-item-clean.active,
.dropdown-item-clean:focus {
  border-radius: 0;
  background-color: rgba($gray2, 0.08) !important;
  cursor: pointer;
  color: $text-default-color;

  &.no-hover {
    background-color: initial !important;
    color: initial !important;
  }
}

.dropdown-header {
  padding: 0.25em 1em;
}

.dropdown-header:hover {
  cursor: default;
}

.dropdown-call-to-action {
  border-top: 1px solid $gray4;
  color: $turquoise;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  &:hover {
    color: $turquoise;
  }
}

.dropdown-item-secondary-label {
  font-size: 0.8rem;
  color: $gray3;
}

.lockstep-dropdown {
  display: inline-block;

  [data-toggle="dropdown"],
  [data-toggle="dropdown-filter"],
  .dropdown-trigger {
    cursor: pointer;
    border-radius: 2px;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.button-action-icon {
      border: none;
      border-radius: 100%;

      &::after {
        display: none;
      }
    }

    &[aria-expanded="true"] {
      border-color: $turquoise;
    }
    &.active-dropdown-trigger {
      border-color: $turquoise;
    }
  }
}

.lockstep-dropdown__menu {
    background-color: $white-background-color;
    border-radius: 2px;
    border: 1px solid $turquoise;
    z-index: 999;

    min-width: 10rem;
    
    visibility: hidden;
    pointer-events: none;

    &:hover {
        cursor: default;
    }

    &.show {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 0.3rem;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
        visibility: visible;
        pointer-events: unset;
        position: absolute !important;
        inset: 0px auto auto 0px !important;
        width: 20rem !important;
        transform: translate(0px, 42px) !important;

        &:not(.breakout) {
            overflow-y: visible;
            overflow-x: hidden;
        }
    }
}

.icon{
  &.go-back-btn{
    cursor: pointer;
  }
}

.hiddenField-makePaymentFlyout{
  pointer-events: none;
  background: transparent;
  position: absolute;
  border: none
}