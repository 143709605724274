@import "../base";

.checkbox {
  display: inline-flex;

  &:hover {
    cursor: pointer;
  }

  .hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    display: none;
  }

  label {
    padding-left: 0.66rem;
    font-weight: 500;
    font-size: 0.93rem;

    &.no-show {
      display: none;
    }
  }

  .checkbox-display {
    display: inline-block;
    width: 16px;
    height: 16px;

    .checkbox-display-border {
      fill: $white-background-color;
      stroke: $gray8;
    }

    .checkbox-display-fill {
      fill: $white-background-color;
    }

    .checkbox-display-fill {
      &.checked{
        fill: $turquoise;
      }
      &.disabled {
        fill: $turquoise;
        opacity: .5;
      }
    }
    
    .checkbox-display-fill:not(.checked).disabled {
      fill: $gray8; // Adjust color when checkbox is not checked and disabled
    }
  }

  .checkbox-display:hover {
    .checkbox-display-fill {
      fill: rgba($turquoise, 0.7);
      &.checked{
        fill: $turquoise;
      }
      &.disabled {
        fill: $turquoise;
        opacity: .5;
      }
    }
  }
}
