@import "../base";

input[type="text"] {
  color: $text-default-color;
}

input[type="text"]::placeholder {
  font-weight: 500;
  color: $gray3;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;
}

.text-input-container .pw-toggle {
  position: absolute;
  top: 2.8rem;
  right: 0.46rem;
  color: $turquoise;
  font-size: 0.85rem;
  cursor: pointer;
}

.text-input {
  width: 100%;
  border: 1px solid $gray2;
  border-radius: 2px;
  padding: 0.4rem;

  &:hover {
    border-color: $dark-navy;
  }

  &:focus {
    border: 1px solid $turquoise;
    box-shadow: none;
  }

  &:disabled {
    background: #f2f2f2;
  }

  &.fs-obscure {
    padding-right: 41px;
  }

  &.input-invalid {
    border: 1px solid #eb5757;
    box-shadow: none;
  }
}

.text-input-row {
  .text-input-container {
    margin-right: 6.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dollar-amount-input {
  min-width:100px;
  text-align: end;
}

.input-invalid-text {
  color: #eb5757;
  padding-top: 0.45em;
  align-items: center;
  justify-content: center;

  .input-invalid-icon {
    margin-right: 0.5rem;
    margin-bottom: 0.15rem;
  }
}

.input-label,
.input-label-inline,
.input-label-light,
.input-label-light-inline {
  font-size: 1.06rem;
  color: $dark-navy;
  margin-bottom: 0.66rem;
  font-weight: 500;

  &.focused {
    color: $turquoise;
  }

  & & {
    margin-bottom: 0;
  }
}

.input-label {
  width: 100%;
}

.input-label-light {
  font-weight: 400;
}
