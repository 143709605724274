@import "../base";

.mode-label-header-container {
  margin-bottom: 1rem;
}

.mode-label-header {
  font-weight: 500;
  font-size: 1.1rem;
}

.emails-container {
  min-height: 1em;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .dropdown-trigger,
  .dropdown-item {
    font-weight: 400;
  }

  .email-template-picker {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.forward-internal-switch-container {
  .switch-label {
    font-size: 1.1rem;
  }
  margin-bottom: 2rem;
}
