@import "../base";

.summary-count-badge {
  margin-left: auto;
  display: inline-flex;
  padding: 0.2rem 0.3rem;
  font-size: 0.93rem;
  line-height: 0.87rem;
  min-width: 2.12rem;
  align-items: center;
  justify-content: center;

  &__spacer {
    width: 0.94rem;
  }

  &--alert {
    background-color: $pink;
    color: $text-inverse-color;
  }

  &--info {
    background-color: transparent;
  }
}
