@import "../base";

/*This is $gray3 with opacity of 0.5 computed to hex #70838f*/
$disabled-color: #b7c1c7;

.button-primary {
  color: $turquoise;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: $white-background-color;
  border: 1px solid $turquoise;
  border-radius: 2rem;
  padding: 0.8rem 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color border-color 0.2s ease;

  &.small {
    padding: 0.86rem 1.36rem;
    line-height: 0.9rem;
  }

  &:hover {
    color: rgba($turquoise, 0.7);
    border-color: rgba($turquoise, 0.7);
  }

  &:disabled {
    color: $disabled-color;
    border-color: $disabled-color;
  }

  &.inverse {
    background: $turquoise;
    color: $text-inverse-color;
  }

  .button-primary-icon {
    margin-right: 0.65rem;
  }
}

// Use this class to style a button element so it looks like a hyperlink, but retains our standard look and feel for primary buttons
.button-primary-link {
  color: $turquoise;
  font-size: 0.9rem;
  padding: 0;
  transition: color 0.2s ease;

  &:hover {
    color: rgba($turquoise, 0.7);
  }

  &:disabled {
    color: $disabled-color;
  }

  &.inverse {
    background: $turquoise;
    color: $text-inverse-color;
  }
}

.button-purple {
  background-color: $purple;
  color: $text-inverse-color;
  padding: 0.6rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: $dark-purple;
  }
}

.button-action-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color border-radius 0.2s ease;

  &.dropdown-trigger {
    justify-content: center;
  }

  &:disabled {
    color: $gray4;
    cursor: auto;
  }

  &:hover:enabled {
    background-color: rgba($turquoise, 0.2);
    border-radius: 2rem;
  }
}

button.button-action-icon {
  padding: 0 0.63rem;
  width: 40px;
  height: 40px;
}

.button-action-icon.data-Tooltip {
  position: relative;
}

.data-Tooltip:disabled::after,
.data-Tooltip:disabled::before {
  display: none;
}

.data-Tooltip::before,
.data-Tooltip::after {
  --scale: 0;
  // --tooltip-color:#1fb6c1;
  --tooltip-color: #333;
  --arrow-size: 1px;
  font-size: 10px;
  position: absolute;
  top: -.4rem;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 30ms transform;
  transform-origin: 'bottom center';
}

.data-Tooltip::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  width: max-content;
  background: #ffffff;
  padding: 5px;
  background: var(--tooltip-color);
  border-radius: .3rem;
  color: #ffffff;
  text-align: center;
}

.data-Tooltip:hover::before,
.data-Tooltip:hover::after {
  --scale: 1
}

.button-secondary {
  color: $turquoise;
  font-size: 0.8rem;
  font-weight: 500;
  border: none;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s ease;

  &:hover {
    color: rgba($turquoise, 0.7);
    border-color: rgba($turquoise, 0.7);
  }

  &:disabled {
    color: $disabled-color;
    border-color: $disabled-color;
    cursor: auto !important;
  }

  .button-secondary-icon {
    margin-right: 0.65rem;
  }
}

.button-indicator {
  border: 1px solid $orange;
  border-radius: 1rem;
  font-size: 0.8rem;
  padding: 0.43rem 0.7rem;
  color: $orange;
  font-weight: 500;
  line-height: 0.94rem;
  min-width: 6.65em;

  &:hover {
    border: 1px solid rgba($orange, 0.7);
    color: rgba($orange, 0.7);
  }
}

.button-back {
  font-weight: 600;
  font-size: 0.933rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.button-primary-square {
  background-color: $turquoise;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  padding: 0.6rem 3.1rem;
  min-width: 9.3rem;
  border-radius: 2px;

  &:hover {
    background: $turquoise-bg-light;
    border: $turquoise-bg-light;
  }

  &:disabled {
    background: $turquoise-bg-lightest;
    border: $turquoise-bg-lightest;
  }

  &.large {
    font-size: 1.33rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    min-width: 30rem;
  }
}

.button-secondary-square {
  color: $turquoise;
  background-color: $white-background-color;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
  padding: 0.6rem 3.1rem;
  min-width: 9.3rem;
  border: 1px solid $turquoise;
  border-radius: 2px;

  &:hover {
    color: $turquoise-bg-light;
    border-color: $turquoise-bg-light;
  }

  &:disabled {
    color: $disabled-color;
    border-color: $disabled-color;
  }

  &.large {
    font-size: 1.33rem;
    min-width: 30rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.button-cancel-square {
  background-color: $pink;
  font-size: 1rem;
  height: 2.4rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0.6rem 3.1rem;
  min-width: 9.3rem;
  border-radius: 1px;

  &:hover {
    background: rgba($pink, 0.7);
    border: rgba($pink, 0.7);
  }

  &:disabled {
    background: rgba($pink, 0.5);
    border: rgba($pink, 0.5);
  }
}

.button-info {
  color: $turquoise;
  padding: 0;
}

.icon-button {
  color: $turquoise;
  cursor: pointer;
}

svg.icon-button {
  color: inherit;
  cursor: inherit;
}

.margin-left-33rem {
  margin-left: .33rem;
}

.margin-top-minus4rem {
  margin-top: -.4rem;
}