@import "../base";

.expandable-nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: inherit;

  &__link {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: $text-inverse-color;
  }
}
