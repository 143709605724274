@import "../base";

.customer-portal {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.customer-portal-content {
  width: 90%;
  max-width: 80rem;
  margin: auto;
  padding-top: 2.6rem;
  background-color: $off-white-background-color;
  flex: 1;
}

.portal-input-label {
  font-size: 1.1rem;
  color: $dark-navy;
  margin-bottom: 1rem;
  font-weight: 500;

  &.focused {
    color: $turquoise;
  }
}

.portal-button-green {
  width: 12rem;
  height: 33px;
  font-weight: 500;
  color: #fff;
  background: $turquoise;
  font-size: 0.93rem;
  line-height: 1em;
  transition: background-color 0.3s ease-in-out;
}

.portal-button-green:hover {
  background-color: $turquoise-bg-light;
}

.portal-button-green:disabled,
.portal-button-green:hover:disabled {
  background-color: $turquoise-bg-lightest;
  cursor: not-allowed;
}

.portal-conversation-thread-item {
  padding: 1.5rem 0;
  border-top: 1px solid #dddddd;
  min-width: $editor-width;

  &:first-child {
    border-top: none;
  }
}

.portal-conversation-thread-item-body {
  margin-left: 2.8rem;
}

.portal-thread-item-sender-name {
  font-size: 1.1rem;
  font-weight: 500;
}

.portal-loading-thread-placeholder {
  width: $editor-width;
  height: 12rem;
}

.portal-table {
  margin-bottom: 3rem;
}

.portal-page-loader {
  margin-top: 25%;

  &.messages {
    margin-top: 8vh;
  }
}

.portal-session-expired,
.portal-account-error {
  width: 100%;
  height: 100%;
  background-color: $white-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .connected-by-lockstep-container {
    justify-content: flex-end;
    padding-right: 2rem;
    padding-top: 1rem;
    width: 100%;
  }

  > svg {
    margin-top: 4.66rem;
    max-height: 6.6rem;
  }

  > h2 {
    margin-top: 2.66rem;
    font-size: 1.33rem;
    line-height: 1.56rem;
    font-weight: 600;
    color: $purple;
  }

  > p {
    margin-top: 0.66rem;
    max-width: 24rem;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $purple;
  }
}

.portal-account-error {
  > h2 {
    margin-top: 2rem;
  }

  > p {
    max-width: 29rem;
  }
}

.customer-portal-language-selector-container {
  margin-left: 2rem;
  margin-bottom: 1.5rem;
}

.customer-portal-payment-confirmation {
  background: $white-background-color;
}

.customer-portal-currency-selector {
  margin-bottom: 1rem;
}

div.add-vendors-modal {
  height: 35rem;
  width: 800px;
  left: calc(50vw - 400px);
  padding: 0;

  .heading-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10000;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.give-feedback-button {
  display: none;
  position: fixed;
  right: 0;
  top: 45%;
  padding: 0.75rem;
  border-radius: 5px 0 0 5px;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  font-weight: 500;
  background-color: $gray2;
  color: $white-background-color;
  cursor: pointer;
  box-shadow: -1px 0 3px 0 #9bafbc;
}

.self-service-table {
  .customer-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;    
  }  
  width: 100%;  
}
.self-service-tablediv {
  overflow-x: auto;  
  width: 100%;   
}
.unapplied-payments__table {
  .customer-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
