@import "../base";

.full-screen-spinner,
.full-screen-spinner:before,
.full-screen-spinner:after {
  background: $turquoise;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.full-screen-spinner {
  color: $turquoise;
  text-indent: -9999em;
  display: inline-block;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  &.white-background{
    background: white;
  }
  &.white-background:before{
    background: white;
  }
  &.white-background:after{
    background: white;
  }
}

.full-screen-spinner:before,
.full-screen-spinner:after {
  position: absolute;
  top: 0;
  content: "";
}

.full-screen-spinner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.full-screen-spinner:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
