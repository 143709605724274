@import "../base";

.add-perspective-modal {
  input {
    min-width: 345px;
  }

  &__title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__label {
    font-size: 1.1rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }
}
