@import "../base";

.statements-summary {
  margin-bottom: 3rem;

  &--columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }

  &-column .statements-summary-item:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.statements-summary-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.statements-summary-item-data {
  font-size: 2.15rem;
  font-weight: 500;
}

.statements-invoice-id,
.statements-transaction-id,
.payments-invoices {
  word-break: break-all;

  &.link {
    color: $turquoise;
  }
}

.statements-currency-row {
  display: flex;
}

/* Filter dropdown */

.button-action-icon.customer-portal-button-filter+.dropdown-menu {
  padding: 1.33rem 1.1rem;
  width: 24rem;
}

.filter-dropdown-buttons {
  margin-top: 2rem;
  font-size: 1.08rem;

  .filter-dropdown-save {
    margin-left: 0.6rem;
    color: $turquoise;

    &:hover {
      color: $turquoise-light;
    }
  }

  .filter-dropdown-reset {
    color: $gray3;

    &:hover {
      color: $gray4;
    }
  }
}

.invoice-num-attachment {
  padding: 0;
  padding-right: 0.66rem;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
}

.invoice-num-attachment-link,
.statements-transaction-id-link,
.payments-invoices-link {
  font-weight: 500;
  font-size: 1rem;
  text-decoration: underline;
}

button.payments-invoices-link{
  padding-left: 0 !important;
}

.unapplied-card {
  margin-bottom: 2.33rem;
}

.unapplied-table-header {
  display: flex;
  padding: 2.13rem 1.6rem;
}

.unapplied-alert-icon {
  color: $red;
  margin-right: 0.6rem;
}

.account-balance-helper-text {
  font-size: 0.933rem;
  line-height: 1.1rem;
  letter-spacing: -0.006rem;
}

.account-balance-info {
  padding: 0.6rem;
  border-color: $turquoise;
  position: absolute !important; 
  z-index: 1;
  &:not(.auto-pay-all-accounts-enable){
    top: 2rem;
  }
  &.auto-pay-all-accounts-enable{
    top: -1rem;
    left:15rem
  }
}

.open-invoices-empty-state,
.documents-empty-state,
.payments-empty-state,
.messages-empty-state,
.user-account-missing-data {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: $purple;

  >svg {
    height: 11rem;
    margin-bottom: 2rem;
  }

  >h2 {
    font-weight: 600;
    font-size: 1.33rem;
    margin-bottom: 0.6rem;
  }

  >p {
    font-size: 1.2rem;
    line-height: 1.35rem;
    margin-bottom: 2rem;
  }
}

.closed-invoices-empty-state-ld,
.payments-empty-state-ld {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: $purple;
  border: 1px solid $gray4;
  padding: 2.6rem;
  border-radius: 2px;
  background-color: #fff;
  background-clip: border-box;

  >svg {
    height: 9rem;
    margin-bottom: 2rem;
  }

  >h2 {
    font-weight: 600;
    font-size: 1.33rem;
    margin-bottom: 0.6rem;
  }

  >p {
    font-size: 1.2rem;
    max-width: 24rem;
    line-height: 1.35rem;
    margin-bottom: 0;
  }
}

.open-invoices-empty-state-ld {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: $purple;
  border: 1px solid $gray4;
  padding: 2.6rem;
  border-radius: 2px;
  background-color: #fff;
  background-clip: border-box;

  >svg {
    height: 9rem;
    margin-bottom: 2rem;
  }

  >h2 {
    font-weight: 600;
    font-size: 1.33rem;
    margin-bottom: 0.6rem;
  }

  >p {
    font-size: 1.2rem;
    max-width: 27rem;
    line-height: 1.35rem;
    margin-bottom: 0;
  }
}

.documents-empty-state>p {
  min-width: 25rem;
}

.messages-empty-state {
  padding-top: 2.66rem;

  >h2 {
    margin-bottom: 2rem;
  }

  >.button-primary {
    margin-bottom: 2.33rem;
  }
}

.user-account-missing-data {
  >svg {
    margin-bottom: 0;
  }
}

.margin-top19rem {
  margin-top: 1.9rem !important;
}