@import "../base";

.site-down-container {
}

.site-down {
  display: flex;
  justify-items: center;

  flex-direction: column;
  text-align: center;
  color: $purple;
  width: 100%;
  height: 100%;
  background-color: $white-background-color;

  .site-down-heading {
    font-weight: bold;
    font-size: 1.33rem;
    margin-top: 1.9rem;
  }

  .site-down-body {
    max-width: 23rem;
    margin: 0 auto;
    font-size: 1.2rem;
  }

  .site-down-connected-by {
    color: $text-default-color;
    padding-bottom: 6.5rem;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.33rem 2.1rem;
    font-weight: 500;
    font-size: 0.666rem;
  }
}
