@import "../base";
@import "./main";

.profile-managment-flyout {
  min-width: 76.8rem;
  .profile-management-button-container {
    display: flex;
    margin-top: 2.66rem;
    margin-bottom: 2.66rem;

    button {
      margin-right: 1.6rem;
    }

    .dropdown-item {
      min-width: 11.66rem;
    }
  }

  .share-dropdown-item {
    color: $turquoise;
    cursor: pointer;
  }

  .card-table {
    max-width: 71.06rem;
  }

  .loading-icon-container {
    align-content: center;
    margin-top: 40vh;
  }

  .profile-managment-content-header-links {
    display: flex;
    margin-top: 1rem;
  }

  .subsidiary-company-dropdown {
    margin-top: 2rem;
    width: 20rem;
  }

  .button-primary-large-dropdown {
    color: $turquoise;
    font-weight: 500;
    font-size: 0.9rem;
    border: 1px solid $turquoise;
    border-radius: 2rem;
    padding: 0.8rem 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;

    .button-primary-icon {
      margin-right: 0.5rem;
    }
  }

  .flyout-heading-close {
    position: absolute;
    right: 2.1rem;
    top: 2.1rem;
    color: $turquoise;
    cursor: pointer;
  }
  hr {
    margin-top: 0;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
  }

  .profile-managment-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .my-profile-header {
      margin-left: 0.88rem;
      margin-bottom: 0;
    }

    h2 {
      font-weight: 600;
      font-size: 1.33rem;
      margin-bottom: 0;
      margin-bottom: 1rem;
    }
  }

  .profile-managment-small-button-container {
    align-self: center;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding-left: 7rem;
      padding-right: 7rem;
      margin: 0.7rem;
    }
  }

  .card-table {
    margin-bottom: 3rem;
    min-width: 72.2rem;
  }
}

.share-profile-flyout {
  min-width: 70vh;
  button {
    min-width: 20vh;
  }
  .flyout-heading-close {
    position: absolute;
    right: 2.1rem;
    top: 2.1rem;
    color: $turquoise;
    cursor: pointer;
  }
  .html-editor {
    width: 100%;
  }
}

.profile-management-form-container {
  overflow-y: scroll;
}

.company-profile-documents-card,
.company-profile-contacts-card {
  padding: 0;
  margin-top: 1.66rem;
}

.perspective-selector__profile-button {
  .dropdown-menu.show {
    transform: translate3d(3.2rem, -6.5rem, 0) !important;
  }
}
