@import "../base";

.customer-portal {
  .html-editor {
    width: $editor-width;
  }
}

.html-editor {
  .toolbar-spacer {
    display: none;
  }

  &.hide-toolbar {
    .ql-toolbar {
      display: none;
    }

    .toolbar-spacer {
      display: block;
      height: 2.85rem;
    }
  }

  .ql-editor {
    padding: 0.6rem;
    min-height: 16rem;
    background: #ffffff;
    border: 1px solid $gray2 !important;
    border-radius: 2px;

    &:hover {
      border-color: $dark-navy !important;
    }

    &:focus {
      border-color: $turquoise !important;
    }
  }

  #toolbar {
    button {
      fill: currentColor;
      &:hover {
        color: rgba(31, 182, 193, 0.7) !important;
      }

      &.ql-active {
        color: $turquoise !important;
      }

      svg {
        fill: currentColor;
      }
    }
  }
}
