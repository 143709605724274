@import "../base";

.home-empty-svg-container {
  width: 20rem;
  height: 13.33rem;
  margin-right: auto;
  margin-left: auto;
}

.svg-matching-bold {
  margin-top: 0.66rem;
  color: $purple;
  font-size: 1.33rem;
  font-weight: bold;
  line-height: 1.6rem;
  text-align: center;
}

.svg-matching-p {
  color: $purple;
  margin-top: 0.66rem;
  font-size: 1.2rem;
  line-height: 1.35rem;
  width: 33.46rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.profile-update-flyout-radios {
  display: flex;
}

.profile-update-flyout-radios label {
  margin-left: 0rem;
  margin-right: 2.66rem;
}

.profile-update-flyout-all-message {
  color: $gray2;
  font-size: 0.93rem;
  line-height: 1.06rem;
  margin-bottom: 2.66rem;
}

.profile-update-flyout-button-container {
  font-size: 1.33rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.profile-update-flyout-card {
  padding-right: 0rem;
  padding-left: 0rem;
  padding-bottom: 0rem;
  margin-top: 2.66rem;
  margin-bottom: 2.66rem;
}

.divider-div-profile-update-flyout {
  height: 0.06rem;
  width: 75.13rem;
  background-color: $gray4;
  position: absolute;
  left: 0rem;
}

.content-header-link-active {
  display: flex;
  text-align: center;
  font-weight: 600;
  font-size: 0.94rem;
  margin-right: 2.66rem;
  padding-bottom: 0.73rem;
  padding-right: 0;
  padding-left: 0;
  border-bottom: solid 0.26rem transparent;
  line-height: 1.07rem;
  border-bottom: solid 0.26rem $turquoise;
}

.profile-update-flyout-search {
  height: 2.4rem;
  width: 23.2rem;
  color: $gray1;
  font-size: 0.93rem;
  font-weight: 500;
  line-height: 1.06rem;
  margin-right: 0rem;
  padding: 0rem;
}
.search-div-container {
  margin-left: 1.66rem;
  margin-bottom: 2.13rem;
  display: flex;
}

.profile-updat-flyout-icon {
  color: $turquoise;
  position: absolute;
  left: 22.66rem;
  top: 2.66rem;
}

.flyout-p {
  font-size: 1.2rem;
  line-height: 1.35rem;
  font-weight: normal;
}

.profile-update-flyout-radios label {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.35rem;
}

.email-template-container {
  margin-top: 4rem;
  width: 45.33rem;
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}

.email-template-container a {
  pointer-events: none;
  cursor: default;
}
.email-template-container button {
  pointer-events: none;
  cursor: default;
}

.flyout-loading-container {
  margin-top: 4rem;
}

.template-flyout-back-button-container {
  display: flex;
}

.template-flyout-back-button-container p {
  font-size: 1.46rem;
  font-weight: 500;
  line-height: 1.66rem;
  align-self: center;
}

.template-flyout-back-button {
  color: $turquoise;
  margin-right: 1.3rem;
  padding: 0rem;
  margin-bottom: 0.86rem;
}
