@import "../base";

$padding-between-items: 2.1rem;

.card-company-info-your-info,
.card-company-info-contact-us {
  padding: 0;
}

.contact-info-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray4;
  padding: $padding-between-items $card-padding;

  &:last-of-type {
    border-bottom: none;
  }

  .contact-primary {
    color: $gray3;
    font-weight: 400;
  }
}

.contact-info-item-primary-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.3rem;
  height: 3.3rem;
  background-color: $purple;
  border-radius: 100%;
  color: $text-inverse-color;

  &.contact-us {
    background-color: $orange;
  }
}

.company-info-item-content {
  margin-left: 1.3rem;
  flex-grow: 1;
}

.company-info-item-content-header {
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-info-item-content-attribute {
  padding-bottom: 0.52rem;
  line-height: 1rem;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.company-info-contact-us,
.company-info-your-info {
  margin-bottom: 2.6rem;
  min-height: 9rem;
}

.company-info-your-info-header {
  border-bottom: 1px solid $gray4;
  padding-left: $card-padding;
  padding-right: $card-padding;
  padding-bottom: $padding-between-items;
  padding-top: $padding-between-items;
}

.company-info-your-info-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $purple;
  padding-top: $card-padding;
  padding-bottom: $card-padding;
}

.company-info-your-info-empty-header {
  padding-top: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.company-info-your-info-empty-description {
  padding-top: 0.4rem;
  font-size: 1.2rem;
  max-width: 23rem;
  text-align: center;
  line-height: 1.4rem;
}
