@import "../base";

.customer-group-dynamic-filter-item {
  display: inline-flex;
  align-items: center;
  min-width: 53.33rem;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  .label {
    padding-left: 1rem;
    font-size: 0.93rem;
    min-width: 3rem;
  }

  .content {
    border: 1px solid $gray4;
    padding: 0.93rem 1rem;
    border-radius: 2px;
    min-width: 25rem;

    &.static {
      padding: 0;
    }

    &.no-border {
      border: none;
    }
  }
}

.customer-group-dynamic-filter-item-editing-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .field {
    width: 16rem;
    margin-right: 2.66rem;
    margin-bottom: 0;
  }

  .comparator {
    width: 9.66rem;
    margin-right: 2.66rem;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0.8rem;
    }
  }
}

.account-groups-info-dropdown {
  &.button {
    border: none;
    color: $turquoise;
  }
  &.content {
    font-weight: 400;
    width: 23.66rem;
    padding: 0.66rem 0.76rem;
    font-size: 0.93rem;

    b {
      font-weight: 500;
    }
  }
}

.account-groups-filter-add-new {
  margin-bottom: 3.33rem;
}

.account-groups-static-filter-dropdown {
  .button {
  }
  .content {
    width: unset;
  }
}

.account-groups-type-explanation {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  line-height: 0.93rem;
  color: $gray2;
}

.account-groups-preview-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.66rem;
  svg {
    max-width: 20.66rem;
    margin-bottom: 2rem;
  }
  h2 {
    color: $purple;
    font-size: 1.33rem;
    line-height: 1.56rem;
    font-weight: 700;
    max-width: 22.66rem;
    text-align: center;
  }
}
