.user-avatar {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.user-avatar-me {
  background-color: $purple;
}

.user-avatar-other {
  background-color: $orange;
}
