@import "../base";
@import "./main";

.first-time-form-card {
  display: flex;
  flex-direction: column;
  padding: 2.73rem;
  padding-bottom: 1rem;
  width: 70.13rem;
  margin-top: 2.66rem;
  margin-bottom: 2.66rem;

  &.edit {
    margin-top: 0;
  }

  &.add-contacts {
    align-self: center;
    width: 74rem;
  }

  &.center {
    align-self: center;
    width: 61.5rem;
  }
}

.first-time-card-form {
  display: flex;
  flex-direction: column;
}

.first-time-card-column {
  display: flex;
  flex-direction: row;
}

.first-time-card-cell {
  margin-bottom: 1.33rem;
  margin-right: 6.66rem;
}

.first-time-card-profile-picture-container p {
  font-size: 1.06rem;
  font-weight: 500;
  line-height: 1.26rem;
}

.first-time-profile-picture {
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white-background-color;
  background-color: $purple;
  margin-right: 1rem;
}

.first-time-profile-content {
  display: flex;
  align-items: center;
  margin-bottom: 1.77rem;
}

.first-time-title-contact {
  width: 84.66rem;
}

.first-time-email-title,
.first-time-title-contact,
.first-time-title-requests {
  font-size: 1.46rem;
  line-height: 1.66rem;
  text-align: center;
  align-self: center;
  margin-top: 2.4rem;
  margin-bottom: 2rem;
}

.contact-add-form-header {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  margin-bottom: 2.33rem;
}

.contact-form-profile-picture {
  height: 4.33rem;
  width: 4.33rem;
  border-radius: 6.66rem;
}

.success-page-marketing-video-placeholder {
  height: 36.46rem;
  width: 75.26rem;
  background-color: $gray4;
  align-self: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.document-form-uploaded-file {
  color: $turquoise;
  font-size: 1.06rem;
  line-height: 1.26rem;
}

.document-form-file-icon {
  margin-left: 0.46rem;
  margin-bottom: 0.16rem;
}

.first-time-admin-contacts-table-card {
  margin-top: 2.66rem;
  padding: 0;
  width: 71.2rem;
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}

// This rule allows for vertical centering of links in lists of documents by overriding a default Bootstrap style that messes up the spacing
.document-list {
  margin-top: revert;
}