@import "../base";

.test-email-config-modal {
  &__status {
    margin-bottom: 2rem;
  }

  &__loader {
    margin-top: 3rem;
    min-height: 5rem;
  }

  &__content {
    max-height: 50vh;
    overflow: auto;

    &__config {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      &__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0.8rem;
      }

      &__label {
      }

      &__item {
        margin-bottom: 0.4rem;
      }
    }
  }
}

.test-email-config-modal-buttons {
  margin-top: 2rem;
}

.smtp-testing-send {
  display: flex;
  align-items: flex-end;

  > .button-primary-square {
    margin-left: .75rem;
    height: 2.4rem;
  }
}
