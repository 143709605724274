@import "../base";

/* TABLE DATA */

.table-data {
  transition: all 2s linear;
  min-height: 6rem;

  .table-data-loader-fullscreen {
    min-height: 12rem;
  }

}

.table-data-content {
  table-layout: fixed;
}

.table-data tr {
  padding: 0 1em;
}

.table-data-header {
  background-color: $gray5;
}

.table-data-header.statements-header {
  background-color: #effcf5;
  border-top: 1px solid #d7f7ee;
  border-bottom: 1px solid #d7f7ee;
}

.table-data-container {
  padding: 1em;
}

.table-data-padding-col {
  width: 1.5%;
}

.table-data-row--no-animate,.table-data-row {
  width: 100%;
  font-size: 0.93rem;
  box-sizing: border-box;
  border-bottom: 0.5px solid $gray5;
  border-top: 0.5px solid $gray5;

  &.hidden {
    height: 0;
    border: none;

    td {
      padding: 0;
    }
  }
  td {
    padding: 0.5em 0.68rem 0.5em 0.68rem;
    vertical-align: middle;
  }
}

.table-data-row {
  &:not(.no-hover):hover {
    --webkit-box-shadow: 0 2px 4px 0 rgba(111, 130, 142, 0.5);
    --moz-box-shadow: 0 2px 4px 0 rgba(111, 130, 142, 0.5);
    box-shadow: 0 2px 4px 0 rgba(111, 130, 142, 0.5);
    opacity: 1;
    cursor: pointer;
  }
}

.customer-portal .table-heading-row {
  padding: 1.33rem 0.7rem;
}

.table-heading-row {
  font-size: 0.87rem;
  padding: 1.33rem 0.8rem;
  font-weight: 400;
  line-height: 1em;

  &.sortable:hover {
    background-color: $gray5;
    cursor: pointer;
  }
}

.table-data-heading-sort {
  display: flex;
  align-items: center;
}

.right-aligned-column {
  text-align: right;
  padding-right: 5% !important;
}

.table-data-row.table-data-row-selected {
  background-color: #e5fdff;

  &:hover {
    background-color: darken(#e5fdff, 2);
  }
}

.table-data-card-header-buttons {
  display: flex;
  flex-wrap: wrap;

  .conversation-action-buttons {
    --gap: 0;
  }

  &__outside {
    @extend .table-data-card-header-buttons;
    margin-top: -2rem;

    .button-primary {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  .button-primary,
  .button-action-icon {
    margin-right: 1.6rem;
    margin-top: 2rem;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .button-action-icon {
    margin-right: 1.9rem;
    margin-top: 2.2rem;
  }
}

.table-data-card-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.inline {
    flex-direction: row;
    align-items: center;
  }

  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-bottom: 1.6rem;

  .text-input-container {
    margin-bottom: 0;
  }

  .table-data-card-header-search {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-right: 0.55rem;
    margin-bottom: 0.533rem;
  }

  &__select-label {
    margin-top: 2rem;
  }
}

.table-data-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0.8rem;
}

.table-data-pagination-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .table-data-pagination-nav-item-arrow-btn {

    &:disabled {
      cursor: not-allowed;
    }
  }

  .table-data-pagination-nav-item.arrow-btn {
    &:disabled {
      color: rgb(199, 197, 197);
      cursor: not-allowed;
    }
  }

  .table-data-pagination-nav-item {
    padding: 0.12rem 0.4rem;
    border: 1px solid transparent;
    border-radius: 2px;
    color: $gray1;
    margin: 0 0.4rem;
    transition: all 0.2s ease;

    &.active,
    &:hover {
      border: 1px solid $gray1;
    }

    &:disabled:hover {
      border: 1px solid transparent;
    }


    &.hidden:hover {
      cursor: default;
      border: 1px solid transparent
    }

    .last-page-btn {
      border-right: 2px solid;
    }

  }
}

table tbody th {
  position: -webkit-sticky;
  left: 0;
  z-index: 9999;
}

.dropdown.button-page-row-count {
  border-radius: 2px;
  color: $gray1;
  font-size: 0.93rem;
  font-weight: 500;

  .button-page-row-count__trigger {
    border: 1px solid $gray1;
    padding: 0.43rem 0.66rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: max-content;
    }
  }

  div {
    min-width: 10rem;
    margin-bottom: 0;
  }
}

.table-data-pagination-rows-to-load {
  width: 10rem;
}

.table-data-empty-render {
  display: flex;
  justify-content: center;
  padding-bottom: 1.3rem;
}

.table-data-center-justify {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-row:hover {
  border-radius: 0;
  background-color: rgba($gray2, 0.08) !important;
  cursor: pointer;
  color: $text-default-color;

  &.no-hover {
    background-color: initial !important;
    color: initial !important;
  }
}

.dropdown-row {
  position: relative;
}

// .info-container {
//   &:hover {
//     .more-info {
//       visibility: visible;
//       display: inline-block;
//       position: absolute;
//       top: 150%;
//       left: 15%;
//       z-index: 1;
//       // width: 70%;
//       // min-height:30px;
//       background-color: $white-background-color;
//       text-align: left;
//       border-radius: 2px;
//       border: solid 1px $turquoise;
//       padding: 10px;
//       transition: 0s all;
//       transition-delay: 1s;
//       box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
//     }
//   }
// }

.more-info span {
  display: block;
}