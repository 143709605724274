@import "../base";

// Lockstep styles

.sequence-cards-container {
  display: flex;
  flex-direction: column;
}

.sequence-card {
  margin-bottom: 1.6rem;
  display: flex;
  padding: 1.33rem;

  max-width: 90rem;

  .sequence-card__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 1.07rem;
      font-weight: 600;
      line-height: 1.23rem;
      margin-bottom: 0.53rem;
    }

    .sequence-card__description {
      line-height: 1.07rem;
      font-size: 0.93rem;
      margin-bottom: 1.6rem;
      flex-grow: 1;
      padding-right: 10rem;
    }

    .sequence-card__last-generated {
      color: $gray3;
      font-size: 0.8rem;
      align-self: flex-start;
    }
  }
}

.sequence-view-card {
  padding: 2.13rem 0.53rem;
  display: inline-flex;

  .sequence-view-card-header,
  .sequence-view-card-steps {
    padding: 0 1.03rem;
  }

  .sequence-view-card-divider {
    margin-top: 2.66rem;
    margin-bottom: 2.66rem;
  }
}

.sequence-view-card-header-description {
  font-size: 1.07rem;
  line-height: 1.37rem;
  margin-bottom: 1.13rem;
}

.sequence-steps-header {
  color: $text-default-color;
  font-size: 1.07rem;
  font-weight: 500;
  line-height: 1.23rem;
  margin-bottom: 1rem;
}

.sequence-steps-list {
  margin-left: 1rem;
}

.sequence-step {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

// Styles built for ATC

.sequences-back-button {
  display: flex;
  align-items: center;
  font-size: 0.93rem;
  font-weight: 600;
  line-height: 1.07rem;
  cursor: pointer;
}

.sequences-preview-text {
  color: $gray2;
}

.sequence-template-card {
  padding: 1.33rem 1.6rem;
  margin-bottom: 1.6rem;

  &:hover {
    cursor: pointer;
    border-color: $turquoise;
  }
}

.sequence-template-title {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.67rem;
}

.sequence-template-description {
  font-size: 1.06rem;
  line-height: 1.2rem;
}

.sequences-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.edit-sequence-input-label {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.66rem;

  &.focused {
    color: $turquoise;
  }
}

.sequence-activation-toggle {
  display: flex;
  align-items: center;
}

.sequence-description-input {
  max-width: 61.3rem;
}

.create-sequence-divider {
  border-top: 1px solid $gray4;
  margin-top: 2.67rem;
  margin-bottom: 2rem;
}

.sequences-secondary-header {
  font-size: 1.2rem;
  font-weight: 500;
}

.sequence-step-card {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
  min-width: 48rem;
  padding: 0.9rem;
  border-color: $gray4;

  &:not(.no-hover):hover {
    cursor: pointer;
    border-color: $turquoise;
  }
}

.sequence-step-order-number {
  font-size: 0.93rem;
  font-weight: 500;
  width: 1.73rem;
  display: flex;
  align-items: center;
}

.sequence-step-input-label {
  margin-bottom: 0.2rem;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.sequence-dropdown {
  width: 16rem;
}

.sequence-dropdown-menu {
  min-width: 16rem;
  max-height: 20rem;
  overflow-y: scroll;
}

.notification-frequency-helper-text {
  font-size: 0.933rem;
  line-height: 1.1rem;
  letter-spacing: -0.006rem;
}

.sequence-step-divider {
  border-top: 1px solid $gray4;
  margin-bottom: 2.67rem;
  margin-top: 2.67rem;
}

.toggle-switch-label {
  margin-left: 0.6rem;
  min-width: 4.5rem;
  font-weight: 500;
}

.sequences-create-header {
  border-bottom: none;
  margin-left: 1rem;
}

.sequence-edit-account-group-checkbox {
  margin-bottom: 1.6rem;
}

.sequence-table-container {
  border: 1px solid $gray4;
  width: 71.2rem;
  margin-bottom: 1rem;
}

.sequence-table-label {
  margin-top: 0;
  font-size: 0.8rem;
  color: $gray3;
}
