/* Card - card.jsx */

@import "../base";

.card {
  border: 1px solid $gray4;
  padding: $card-padding;
  border-radius: 2px;

  &.clickable:hover {
    border-color: $turquoise;
  }
}

.card-table {
  padding: 0;
}

.card-conversation {
  padding: 1.66rem 1rem;
  padding-right: 0;
  max-width: 80rem;
}

.card-crud-list {
  padding: 0;
  padding-top: 1rem;
}

.card-attribute {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 0;
  border-bottom: 1px solid $gray4;
}

.card-attribute-column {
  display: flex;
  flex-direction: column;
  align-items: right;
  padding: 1em 0;
  border-bottom: 1px solid $gray4;
}

.card-attribute-column .auto-complete-dropdown {
  border:1px solid #576e7c;
}

.card-attribute:last-child {
  border-bottom: none;
}

.card-attribute-editing {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.635em 0;
}

.card-attribute-label {
  color: #666666;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 15em;
}

.card-attribute-label-long {
  color: #666666;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 19em;
}

.card-attribute-label-no-max {
  color: #0F3044;
  flex-basis: 0;
  flex-grow: 1;
}

.card-attribute-content {
  flex-basis: 0;
  flex-grow: 1;
}

.card-attribute-content-display {
  padding-left: 0.4em;
}

.card-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 3em;
}

.card-heading {
  font-size: 1.45rem;
}

.card-bottom-buttons {
  display: flex;
  margin-top: 2.5rem;
}

.card-headings {
  max-width: 60%;
}

.card-description {
  font-size: 1em;
}

.card-title {
  font-size: 1.6rem;
  margin-bottom: 0;
  font-weight: 500;
}

.card-info {
  padding: 0.6rem;
  border-color: $turquoise;
  position: absolute;
  top: 2rem;
  right: 0;
  z-index: 1;
}
