@import "../base";

// *** Start Spin Cycle code ***
@mixin ic-Spin-cycle-animation(
  $animation-name: spin-cycle,
  $animation-grow-to: 1,
  $animation-shrink-to: 1,
  $color-spin-highlight: $text-inverse-color,
  $color-spin-base: black
) {
  @keyframes #{$animation-name} {
    from {
      stroke: $color-spin-highlight;
      transform: scale($animation-grow-to);
    }
    to {
      stroke: $color-spin-base;
      transform: scale($animation-shrink-to);
    }
  }
}

@mixin ic-Spin-cycle-branding(
  $animation-name: spin-cycle,
  $animation-grow-to: 1,
  $animation-shrink-to: 1,
  $spin-width: 72px,
  $spin-height: 72px,
  $spin-margin: 12px 24px,
  $color-spin-base: rgba(white, 0),
  $color-spin-highlight: $text-inverse-color,
  $number-of-paths: 8
) {
  @include ic-Spin-cycle-animation(
    $animation-name,
    $animation-grow-to,
    $animation-shrink-to,
    $color-spin-highlight,
    $color-spin-base
  );
  > svg {
    > * {
      stroke: $color-spin-base;
      animation-name: $animation-name;
      animation-duration: #{$number-of-paths * 0.15}s;
      @for $i from 1 through ($number-of-paths) {
        &:nth-of-type(#{$i}) {
          animation-delay: #{$i * 0.15}s;
        }
      }
    }
  }
}

%ic-Spin-cycle {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
  > svg {
    display: block;
    overflow: visible;
    > * {
      transform: translate3d(0, 0, 0);
      transform-origin: center;
      animation-iteration-count: infinite;
    }
  }
}

.icon-spinner {
  @extend %ic-Spin-cycle;
  // pass no parameters into the mixin, and you get a classic spinner
  @include ic-Spin-cycle-branding;
}
