@import "../base";

.dropdown.language-selector {
  > button {
    border: none;
    color: $turquoise;
    font-size: 0.93rem;
    font-weight: 600;
  }
}

.language-selector-icon {
  margin-right: 0.4rem;
}
