@import "base";

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
  min-width: 960px;
  font-size: 15px;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: $text-default-color;
  width: 100vw;
  overflow-x: hidden;

  @media (max-width: 950px) {
    overflow-x: auto;
  }
}

body {
  color: $text-default-color;
  background-color: $off-white-background-color;
}

.ReactModal__Body--open {
  overflow: hidden;
}

/* Override Bootstraps defaults styles. */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
textarea,
div,
li,
nav {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

// Hide arrows on number type input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  margin: 0px;
}

h1 {
  font-size: 42px;
  font-weight: 400;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  font-weight: 400;
}

h4 {
  font-size: 20px;
  font-weight: 400;
}

h5 {
  font-size: 18px;
  font-weight: 400;
}

h6 {
  font-size: 16px;
  font-weight: 400;
}

.font-small {
  font-size: 11px;
}

.font-med {
  font-size: 13px;
}

.font-grey {
  color: #999999;
}

.med-heading .italic {
  font-style: italic;
}

.warning-color-font {
  color: #eb5757 !important;
}

.main-flood-color-bg {
  background-color: #0f3044;
  color: #ffffff;
}

.verified-color-font {
  color: #008952;
}

.success-color-font {
  color: #27ae60;
}

.unverified-color-font {
  color: #f6ad33;
}

.verified-color-bg {
  background-color: #008952;
}

.teal-color-bg {
  background-color: #00c9b2;
}

.dark-gray-font {
  color: #999999;
}

.light-gray-font {
  color: #dddddd;
}

.teal-color-font {
  color: #00c9b2;
}

.c-gray-font {
  color: #cccccc;
}

.font-turquoise {
  color: $turquoise;
}

.light-gray-bg {
  background-color: #dddddd;
}

.dark-gray-bg {
  background-color: #999999;
}

.white-color-bg,
.bg-white {
  background-color: $white-background-color;
}

.white-color-font {
  color: $text-inverse-color;
}

.warning-color-bg,
.warning-color-bg:hover {
  background-color: #fe6a6a;
  color: #ffffff;
}

.notification-color-font {
  color: #695dfa;
}

.transparent-bg {
  background: rgba(0, 0, 0, 0);
}

.modal-content {
  outline: none !important;
  border-color: #4b4e61 !important;
}

input,
form>div>input,
.input-defualt {
  padding: 5px;

  border-style: solid;
  border-color: #999999;
  border-width: thin;
}

input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2d9cdb;
}

a {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

.hover-pointer:hover {
  cursor: pointer;
}

button,
.btn,
.btn-secondary {
  border: none;
  border-radius: 0;
  padding: 10px;
  color: inherit;
  background-color: inherit;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.btn-sm {
  font-size: 0.75em;
}

.btn-md {
  border-radius: 0;
}

button:focus,
.btn:focus,
.btn-secondary:focus {
  outline: none;
  box-shadow: none;
}

.inactive-button,
.inactive-button:hover {
  border-color: #00c9b2;
  background-color: #00c9b2;
}

.active-button,
.active-button:hover {
  border-color: #00c9b2;
  background-color: #00c9b2;
}

.button-blue {
  border-radius: 0px;
  background: #097abf;
  color: #fff;
  padding: 0.75em 1.5em;
  font-weight: 500;
}

.button-blue:disabled,
.button-blue:disabled:hover {
  background: #ccc;
}

.button-blue:hover {
  background-color: #0e92e3;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.custom-select {
  border-radius: 0;
  border: 1px solid #c7c7c7;
  color: initial;
}

.custom-select:focus {
  border: 1px solid #2d9cdb;
  box-shadow: none;
}

input[readonly] {
  cursor: default;
}

.flex-even {
  flex: 1;
}

.custom-control.inline {
  display: inline-block;
  padding-right: 2em;
}

.custom-control.inline:last-child {
  padding-right: 0;
}

/* LOCKSTEP CSS FRAMEWORK */
.lockstep-icon {
  font-size: inherit;
  height: 15px;
  padding-right: 5px;
  padding-bottom: 1px;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.of-hidden {
  overflow: hidden;
}

.of-auto {
  overflow: auto;
}

.of-auto-x {
  overflow-x: auto;
}

.of-auto-y {
  overflow-y: auto;
}

.of-hidden-x {
  overflow-x: hidden;
}

.of-hidden-y {
  overflow-y: hidden;
}

.w-auto {
  width: auto !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-500 {
  width: 500px;
}

.max-width-100 {
  max-width: 100px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-600 {
  max-width: 600px;
}

.max-100 {
  max-width: 100%;
}

.clickable {
  cursor: pointer;
  padding: 0;
}

.underline {
  text-decoration: underline;
}

.border-none {
  border: none !important;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
}

.clearfix:after {
  clear: both;
}

.v-align-bottom {
  vertical-align: text-bottom;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.align-left {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-split {
  display: flex;
  justify-content: space-between;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-baseline {
  align-items: baseline;
}

.right-aligned {
  display: flex;
  justify-content: flex-end;
}

.full-height-minus-header {
  min-height: calc(100vh - 118px);
}

.divider,
.divider-light {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #999999;
  margin: 14px 0;
}

.divider-light {
  border-top: 1px solid #dddddd;
}

.separator {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #ddd;
}

.margin-bottom-zero {
  margin-bottom: 0 !important;
}

.gray-3 {
  color: #999999;
}

.placeholder {
  color: #666666;
}

.relative {
  position: relative;
}

.width-235-px {
  width: 235px;
}