@import "../base";

.manage-auto-pay-flyout {
  h2 {
    font-size: 1.07rem;
    font-weight: 500;
  }

  &__pay-on-radio {
    margin-top: 1rem;

    .radio-input__input {
      margin-bottom: 1rem;
    }
  }
}
