@import "../base";

.auto-pay-indicator {
  &.dropdown {
    position: absolute;
  }
  &.button {
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 0;
    border: none;
    padding: 0;
    -webkit-user-select: none;
    user-select: none;
    &.disabled {
      color: $gray7;
      cursor: not-allowed;
    }
  }
  &.content {
    padding: 0.8rem;
    font-size: 0.8rem;
    line-height: 0.93rem;
    min-width: 16.5rem;
  }
}
